import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Hamburger from 'hamburger-react'
import { Link, useNavigate } from 'react-router-dom'

import '../../styles/style.css'

import logo from '../../assets/images/logo.png'
import downArrow from '../../assets/images/down.svg'
import mobileLogo from '../../assets/images/mobile-logo.png'
import search from '../../assets/images/search.svg'

import { FaUserCircle } from 'react-icons/fa'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { UserDataDTO } from '../../types/User'
import { DropData } from '../../types/Drop'
import SearchBar from '../SearchBar'
import { debounce } from 'lodash'
import fetchImages, { fetchSingleImage } from '../../utils/FetchImages'

export default function Navbar() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const authToken = localStorage.getItem('authentication') || ''
  const userId = localStorage.getItem('user') || ''
  const userCompanyId = localStorage.getItem('company') || ''
  const companyName = localStorage.getItem('companyName') || ''

  const [userToken, setUserToken] = useState<string | null>('')
  const [userData, setUserData] = useState<UserDataDTO | null>()
  const [companyId, setCompanyId] = useState<string | null>('')
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false)
  const [hamburgerAnim, setHamburgerAnim] = useState(false)
  const [mobileSubMenu, setMobileSubMenu] = useState(false)

  const [showResults, setShowResults] = useState(false)
  const [searchResults, setSearchResults] = useState<DropData[]>([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [userImageUrl, setUserImageUrl] = useState<string | null | undefined>()

  const [dropImages, setDropImages] = useState<any>({})

  const getUserData = async () => {
    try {
      const user = await auth.findOneUser(userId)
      delete user?.data?.password
      setUserData(user?.data)
      if (user?.data?.image) {
        const url = await fetchSingleImage(user?.data?.image, '/users/profile-image/get/', auth)
        setUserImageUrl(url)
      }
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  const getCompanyData = async () => {
    try {
      const companyData = await auth.findOneCompany({ id: userCompanyId || '' })
      localStorage.setItem('companyName', companyData?.data?.link)
    } catch (error) {
      console.error(error)
    }
  }

  const wrapperRef = useRef(null)

  const getSearchResults = async (keyword: string) => {
    if (keyword.length <= 2) return

    const results = await auth.getDropByName({
      name: keyword,
      limit: 12,
      page: 0
    })
    setSearchResults(results?.data?.results)
  }

  const debouncedGetSearchResults = useCallback(
    debounce((value) => {
      getSearchResults(value)
      setSearchKeyword(value)
    }, 500),
    []
  )

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowResults(false)
          setSearchResults([])
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchstart', handleClickOutside)
      }
    }, [ref])
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') navigate(`/anuncios?name=${searchKeyword}`)
  }

  useOutsideAlerter(wrapperRef)

  useEffect(() => {
    setUserToken(authToken)
    setCompanyId(userCompanyId)
    getUserData()
    if (userCompanyId) getCompanyData()
  }, [authToken, userCompanyId])

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const drop of searchResults) {
        await fetchImages(drop.image, '/drop/drop-image/get/', setDropImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [searchResults])

  return (
    <>
      <header className="anor_fn_header">
        <div className="container">
          <div className="header_in">
            {/* left content */}
            <div className="header_left">
              <div className="fn_logo">
                <Link
                  onClick={() => {
                    setToggleMobileMenu(false)
                    setHamburgerAnim(false)
                  }}
                  to={'/'}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={logo} alt="" />
                </Link>
              </div>
              <div className="nav_list">
                <ul className="main_menu">
                  <li>
                    <Link
                      onClick={() => {
                        setToggleMobileMenu(false)
                        setHamburgerAnim(false)
                      }}
                      to={'/anuncios'}
                    >
                      Anúncios
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setToggleMobileMenu(false)
                        setHamburgerAnim(false)
                      }}
                      to={'/drops'}
                    >
                      Drops
                    </Link>
                  </li>
                  {companyId && (
                    <li>
                      <Link
                        onClick={() => {
                          setToggleMobileMenu(false)
                          setHamburgerAnim(false)
                        }}
                        to={'/product'}
                      >
                        Vender Agora
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      onClick={() => {
                        setToggleMobileMenu(false)
                        setHamburgerAnim(false)
                      }}
                      to={'/about'}
                    >
                      Sobre
                    </Link>
                  </li>
                  {userData?.role === 'ADMIN' && (
                    <li>
                      <Link
                        onClick={() => {
                          setToggleMobileMenu(false)
                          setHamburgerAnim(false)
                        }}
                        to={'/admin'}
                      >
                        Painel Admin
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            {/* right content */}
            <div className="header_right">
              <SearchBar />

              <div className="fn_signin">
                <div className="nav_list">
                  <div className="main_menu">
                    <li className="menu-item-has-children">
                      {userToken && !userData?.image && (
                        <FaUserCircle
                          style={{
                            width: '35px',
                            height: '35px',
                            color: 'gray'
                          }}
                        />
                      )}
                      {userToken && userData?.image && (
                        <img
                          src={userImageUrl as string}
                          alt="Profile"
                          style={{
                            width: '35px',
                            height: '35px',
                            color: 'gray',
                            borderRadius: '50%'
                          }}
                        />
                      )}
                      <ul className="sub-menu">
                        <li>
                          <Link
                            onClick={() => {
                              setToggleMobileMenu(false)
                              setHamburgerAnim(false)
                            }}
                            to={'/perfil'}
                          >
                            Editar Perfil
                          </Link>
                        </li>
                        {!companyId
                          ? (
                          <li>
                            <Link
                              onClick={() => {
                                setToggleMobileMenu(false)
                                setHamburgerAnim(false)
                              }}
                              to={'/loja'}
                            >
                              Criar Loja
                            </Link>
                          </li>
                            )
                          : (
                          <>
                            <li>
                              <Link
                                onClick={() => {
                                  setToggleMobileMenu(false)
                                  setHamburgerAnim(false)
                                }}
                                to={'/meus-anuncios'}
                              >
                                Meus Anúncios
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  setToggleMobileMenu(false)
                                  setHamburgerAnim(false)
                                }}
                                to={`/l/${companyName}`}
                              >
                                Ver loja
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => {
                                  setToggleMobileMenu(false)
                                  setHamburgerAnim(false)
                                }}
                                to={'/loja'}
                              >
                                Editar Loja
                              </Link>
                            </li>
                          </>
                            )}
                        <li>
                          <a
                            onClick={() => {
                              auth.signOut()
                              navigate('/')
                            }}
                          >
                            Sair
                          </a>
                        </li>
                      </ul>
                    </li>
                  </div>
                </div>
              </div>

              {!userToken && (
                <div className="fn_signin">
                  <Link className="anor_fn_button small" to={'/signin'}>
                    Entrar
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Mobile menu */}
      <div className="anor_fn_mobile_menu">
        <div className="mobile_menu_inner">
          <div className="mobile_in">
            <div className="logo">
              <a>
                <img
                  src={mobileLogo}
                  alt=""
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setToggleMobileMenu(false)
                    setHamburgerAnim(false)
                    navigate('/')
                  }}
                />
              </a>
            </div>
            <div className="trigger">
              <div className="hamburger hamburger--slider">
                <div className="hamburger-box">
                  <Hamburger
                    toggled={hamburgerAnim}
                    toggle={() => {
                      setHamburgerAnim(!hamburgerAnim)
                      setToggleMobileMenu(!toggleMobileMenu)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dropdown"
          style={
            toggleMobileMenu
              ? { display: 'block', marginTop: '70px' }
              : { display: 'none' }
          }
        >
          <div className="dropdown_inner">
            <ul className="vert_menu">
              <div className="fn_search">
                <input
                  type="text"
                  id="search_data"
                  autoComplete="off"
                  className="form-control input-lg"
                  placeholder="Procurar"
                  onChange={e => debouncedGetSearchResults(e.target.value)}
                  onFocus={() => setShowResults(true)}
                  ref={wrapperRef}
                  onKeyDown={handleKeyDown}
                />
                <img src={search} alt="" className="fn__svg" />

                <ul
                  className="search-menu"
                  style={
                    showResults && searchResults.length > 0
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  {searchResults.map((product, index) => (
                    <li key={index}>
                      <a
                        onClick={() => navigate(`/drop/${product?.id}`)}
                        ref={wrapperRef}
                      >
                        <img
                          // src={HandleImage('/drop/drop-image/get/', product?.image)}
                          src={dropImages
                            ? dropImages[product?.image?.split('.')[0]]
                            : null}
                          alt=""
                          width={50}
                        />
                        {product?.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <br />

              {userToken && (
                <li className="menu-item-has-children">
                  <a onClick={() => setMobileSubMenu(!mobileSubMenu)}>
                    Meu Perfil
                    <img src={downArrow} alt="" className="fn__svg" />
                  </a>
                  <ul
                    className="sub-menu"
                    style={
                      mobileSubMenu ? { display: 'block' } : { display: 'none' }
                    }
                  >
                    {!companyId
                      ? (
                      <li>
                        <Link
                          onClick={() => {
                            setToggleMobileMenu(false)
                            setHamburgerAnim(false)
                          }}
                          to={'/loja'}
                        >
                          Criar Loja
                        </Link>
                      </li>
                        )
                      : (
                      <>
                        <li>
                          <Link
                            onClick={() => {
                              setToggleMobileMenu(false)
                              setHamburgerAnim(false)
                            }}
                            to={'/meus-anuncios'}
                          >
                            Meus Anúncios
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setToggleMobileMenu(false)
                              setHamburgerAnim(false)
                            }}
                            to={`/l/${companyName}`}
                          >
                            Ver Loja
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setToggleMobileMenu(false)
                              setHamburgerAnim(false)
                            }}
                            to={'/loja'}
                          >
                            Editar Loja
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              setToggleMobileMenu(false)
                              setHamburgerAnim(false)
                            }}
                            to={'/product'}
                          >
                            Vender Agora
                          </Link>
                        </li>
                      </>
                        )}
                    {userToken && (
                      <li>
                        <a
                          onClick={() => {
                            auth.signOut()
                            navigate('/')
                          }}
                        >
                          Sair
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {!userToken && (
                <li>
                  <Link
                    onClick={() => {
                      setToggleMobileMenu(false)
                      setHamburgerAnim(false)
                    }}
                    to={'/signin'}
                  >
                    Entrar
                  </Link>
                </li>
              )}
              <li>
                <Link
                  onClick={() => {
                    setToggleMobileMenu(false)
                    setHamburgerAnim(false)
                  }}
                  to={'/anuncios'}
                >
                  Anúncios
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setToggleMobileMenu(false)
                    setHamburgerAnim(false)
                  }}
                  to={'/drops'}
                >
                  Drops
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    setToggleMobileMenu(false)
                    setHamburgerAnim(false)
                  }}
                  to={'/about'}
                >
                  Sobre
                </Link>
              </li>
              {userData?.role === 'ADMIN' && (
                <li>
                  <Link
                    onClick={() => {
                      setToggleMobileMenu(false)
                      setHamburgerAnim(false)
                    }}
                    to={'/admin'}
                  >
                    Painel Admin
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
