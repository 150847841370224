import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import '../../styles/style.css'
import { GetUserProductsDTO } from '../../types/Product'
import { GetOneCompanyDTO } from '../../types/Company'

import cancel from '../../assets/images/cancel.svg'
import { GiConfirmed } from 'react-icons/gi'
import { IoMdCloseCircleOutline, IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import Announcements from './components/Announcements'
import Stores from './components/Stores'
import Drops from './components/Drops'
import { DropData } from '../../types/Drop'
import { Report } from '../../types/Report'
import { setLabelStyle } from './utils/LabelStyle'

export default function Admin() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const userId = localStorage.getItem('user') || ''

  const [editModal, setEditModal] = useState('')
  const [announcementModal, setAnnoucementModal] = useState('')
  const [companyModal, setCompanyModal] = useState('')
  const [reportModal, setReportModal] = useState('')
  const [reportData, setReportData] = useState<Report>()
  const [action, setAction] = useState<string | undefined>('')

  const [label, setLabel] = useState('announcements')
  const [section, setSection] = useState('announcements')
  const [category, setCategory] = useState<string | undefined>('analysis')
  const [companyCategory, setCompanyCategory] = useState<string | undefined>(
    'active'
  )
  const [name, setName] = useState<string | undefined>('')
  const [companyName, setCompanyName] = useState<string | undefined>('')
  const [products, setProducts] = useState<GetUserProductsDTO[]>([])
  const [companies, setCompanies] = useState<GetOneCompanyDTO[]>([])
  const [drops, setDrops] = useState<DropData[]>([])
  const [reports, setReports] = useState<Report[]>([])

  const [reportedByUser, setReportedByUser] = useState('')
  const [reportedCompany, setReportedCompany] = useState('')

  const getLabelStyle = (label: string) => {
    const labelStyle = setLabelStyle(label)
    return labelStyle
  }

  const getSearchAnnouncementsResults = async (keyword: string) => {
    if (!keyword) return setName('')
    if (keyword.length <= 2) return
    setName(keyword)
  }

  const getSearchCompanyResults = async (keyword: string) => {
    if (!keyword) return setCompanyName('')
    if (keyword.length <= 2) return
    setCompanyName(keyword)
  }

  const getAllProducts = async () => {
    const query: { state: string | undefined; name?: string | undefined } = {
      state: category
    }
    if (name) query.name = name
    if (!name) delete query.name

    const response = await auth.getAllProductsByFilter(query)
    setProducts(response?.data?.results)
  }

  const getAllCompanies = async () => {
    const query: {
      limit?: number
      page?: number
      name?: string
      order?: string
      state: string | undefined
    } = {
      state: companyCategory
    }

    if (companyName) query.name = companyName
    if (!companyName) delete query.name

    const response = await auth.getAllCompaniesByFilter(query)
    setCompanies(response?.data?.results)
  }

  const getAllDrops = async () => {
    const response = await auth.getAllDrops()
    setDrops(response?.data?.results)
  }

  const handleUpdateAnnouncement = async (data: {
    id: string
    state: string | undefined
  }) => {
    try {
      const updateProduct = await auth.updateUserProductData({
        id: data.id,
        state: data.state
      })
      setAnnoucementModal('')

      if (updateProduct?.data?.affected > 0)
        return data.state === 'desactivated'
          ? toast.success('Anúncio desativado')
          : toast.success('Anúncio aprovado')
      return toast.error('Erro ao atualizar anúncio')
    } catch (error) {
      console.error(error, '\n')
      return toast.error('Erro ao atualizar anúncio')
    }
  }

  const handleUpdateCompany = async (data: {
    id: string
    action: string | undefined
  }) => {
    try {
      const query: { companyId: string; verified?: boolean; state?: string } = {
        companyId: data.id
      }
      if (data.action === 'blocked') query.state = 'blocked'
      if (data.action === 'verify') query.verified = true
      if (data.action === 'unverify') query.verified = false

      const updateCompany = await auth.updateCompany(query)
      setCompanyModal('')
      if (updateCompany?.data?.affected > 0)
        return action === 'blocked'
          ? toast.success('Loja bloqueada')
          : toast.success('Loja verificada')

      return toast.error('Erro ao atualizar anúncio')
    } catch (error) {
      console.error(error, '\n')
      return toast.error('Erro ao atualizar loja')
    }
  }

  const getUserData = async () => {
    try {
      const user = await auth.findOneUser(userId)
      if (user?.data?.role !== 'ADMIN' || !user?.data)
        return navigate('/', { replace: true })
    } catch (error) {
      console.error(error, '\n')
      return ''
    }
  }

  const getCompanyReports = async () => {
    try {
      const response = await auth.getAllDropsByReportedCompany({
        reportedCompany: companyModal
      })
      setReports(response?.data)
    } catch (error) {
      console.error(error, '\n')
    }
  }

  const getDetailedReportData = async () => {
    try {
      const userData = await auth.findOneUser(reportData?.reportedBy || null)
      const companyData = await auth.findOneCompany({
        id: reportData?.reportedCompany || ''
      })
      setReportedByUser(userData?.data?.name)
      setReportedCompany(companyData?.data?.link)
    } catch (error) {
      console.error(error, '\n')
    }
  }

  const handleActionText = (action: string) => {
    const actionMapper = {
      verify: 'Verificar',
      blocked: 'Bloquear',
      unverify: 'Desverificar'
    }
    return actionMapper[action as keyof typeof actionMapper]
  }

  useEffect(() => {
    if (section === 'announcements') getAllProducts()
  }, [category, name, announcementModal])

  useEffect(() => {
    if (section === 'stores') getAllCompanies()
  }, [section, companyName, companyModal, companyCategory])

  useEffect(() => {
    if (section === 'drops') getAllDrops()
  }, [category, section, name])

  useEffect(() => {
    if (action === 'report') getCompanyReports()
  }, [action])

  useEffect(() => {
    if (reportData) getDetailedReportData()
  }, [reportData])

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <>
      <div
        className="anor_fn_modal share_box"
        style={
          announcementModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in">
          <div className="modal_closer">
            <img
              src={cancel}
              alt=""
              className="fn__svg"
              onClick={() => setAnnoucementModal('')}
            />
          </div>
          <div className="modal_content">
            <div className="share_title">
              {action === 'available' ? 'Aprovar' : 'Desativar'} anúncio?
            </div>
            <div className="share_list">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      setEditModal('')
                      handleUpdateAnnouncement({
                        state: action,
                        id: announcementModal
                      })
                    }}
                    target="_blank"
                  >
                    <GiConfirmed />
                  </a>
                </li>
                <li>
                  <a onClick={() => setAnnoucementModal('')} target="_blank">
                    <IoMdCloseCircleOutline />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="anor_fn_modal share_box"
        style={
          companyModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in">
          <div className="modal_closer">
            <img
              src={cancel}
              alt=""
              className="fn__svg"
              onClick={() => setCompanyModal('')}
            />
          </div>
          <div className="modal_content">
            <div className="share_title">
              {handleActionText(action || '')} loja?
            </div>
            <div className="share_list">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      handleUpdateCompany({ action, id: companyModal })
                    }}
                    target="_blank"
                  >
                    <GiConfirmed />
                  </a>
                </li>
                <li>
                  <a onClick={() => setCompanyModal('')} target="_blank">
                    <IoMdCloseCircleOutline />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="anor_fn_modal share_box"
        style={
          reportModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in" style={{ height: '70%', overflowY: 'auto' }}>
          <div className="modal_closer">
            <img
              src={cancel}
              alt=""
              className="fn__svg"
              onClick={() => setReportModal('')}
            />
          </div>
          <div className="modal_content">
            <div className="share_title" style={{ marginBottom: '2%' }}>
              Denuncias
            </div>

            <div className="anor_fn_product_offers">
              <div
                className="offer_content"
                style={{ paddingRight: '7%', paddingLeft: '7%' }}
              >
                {reports.map((data, index) => (
                  <div
                    key={index}
                    className="offer_item report_item"
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                    onClick={() => {
                      setReportData(data)
                      setReportModal('')
                    }}
                  >
                    <p>
                      {index + 1}. {data?.report}
                    </p>
                    <IoIosArrowForward className="report_item_icon" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="anor_fn_modal share_box"
        style={
          reportData
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in" style={{ height: '30%', overflowY: 'auto' }}>
          <div className="modal_closer">
            <img
              src={cancel}
              alt=""
              className="fn__svg"
              onClick={() => setReportData(undefined)}
            />
          </div>
          <div className="modal_content">
            <div className="share_title" style={{ marginBottom: '2%' }}>
              Denúncia
            </div>
            <div className="modal_content">
              <div className="modal_data_block">
                <p>RAZÃO:</p>
                <span>{reportData?.report}</span>
              </div>

              <div className="modal_data_block">
                <p>DENUNCIADO POR:</p>
                <span>{reportedByUser}</span>
              </div>

              <div className="modal_data_block">
                <p>LOJA DENUNCIADA:</p>
                <span
                  className="modal_link"
                  onClick={() => navigate(`/l/${reportedCompany}`)}
                >
                  {reportedCompany}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="anor_fn_collectionsingle">
        <div className="anor_fn_tabs">
          <div className="tab_filter_wrapper">
            <div className="tab_filter">
              <ul>
                <li className="current" id="disp">
                  <a
                    onMouseEnter={() => {
                      setLabel('announcements')
                    }}
                    onClick={() => setSection('announcements')}
                  >
                    <span className="text">Anúncios</span>
                  </a>
                </li>
                <li id="stores">
                  <a
                    onMouseEnter={() => {
                      setLabel('stores')
                    }}
                    onClick={() => setSection('stores')}
                  >
                    <span className="text">Lojas</span>
                  </a>
                </li>
                <li id="drops">
                  <a
                    onMouseEnter={() => {
                      setLabel('drops')
                    }}
                    onClick={() => setSection('drops')}
                  >
                    <span className="text">Drops</span>
                  </a>
                </li>
              </ul>
              <span className="ccc" style={getLabelStyle(label)}></span>
            </div>
          </div>

          {section === 'announcements' && (
            <Announcements
              setCategory={setCategory}
              getSearchAnnouncementsResults={getSearchAnnouncementsResults}
              products={products}
              category={category}
              setEditModal={setEditModal}
              editModal={editModal}
              setAnnoucementModal={setAnnoucementModal}
              setAction={setAction}
            />
          )}

          {section === 'stores' && (
            <Stores
              setEditModal={setEditModal}
              editModal={editModal}
              setAction={setAction}
              setCompanyCategory={setCompanyCategory}
              getSearchCompanyResults={getSearchCompanyResults}
              companies={companies}
              companyCategory={companyCategory}
              setCompanyModal={setCompanyModal}
              setReportModal={setReportModal}
            />
          )}

          {section === 'drops' && <Drops drops={drops} />}
        </div>
      </div>
    </>
  )
}
