import React from 'react'
import { FaStar } from 'react-icons/fa'

import { Container, Radio, RatingDiv } from './style'

export default function Rating(props: {
  rate: number
  setRating?: React.Dispatch<React.SetStateAction<number>>
  disabled: boolean
}) {
  const changeRating = (givenRating: number) => {
    // @ts-ignore
    if (props.disabled) props.setRating(givenRating)
  }

  return (
    <>
      <Container>
        {[...Array(5)].map((item, index) => {
          const givenRating = index + 1
          return (
            <label key={index}>
              <Radio
                type="radio"
                value={props.rate}
                onClick={() => {
                  changeRating(givenRating)
                }}
              />
              <RatingDiv>
                <FaStar
                  color={
                    givenRating < props.rate || givenRating === props.rate
                      ? '#ffd100'
                      : 'rgb(192,192,192)'
                  }
                />
              </RatingDiv>
            </label>
          )
        })}
      </Container>
    </>
  )
}
