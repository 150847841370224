import { AxiosResponse } from 'axios'
import { createContext } from 'react'
import {
  CompanyCreationDTO,
  GetAllCompaniesByFilterDTO,
  GetOneCompanyDTO,
  UpdateCompanyDTO
} from '../../types/Company'
import {
  CreateDropDTO,
  DefaultRequestPaginator,
  GetDropByName,
  GetProductsSizeByDropIdDTO,
  UpdateDropData
} from '../../types/Drop'
import { CreateEvaluationDTO } from '../../types/Evaluation'
import {
  CreateProductDTO,
  GetAllProductsFiltered,
  GetCustomProductFilter,
  GetProductByDropId,
  UpdateProductDTO
} from '../../types/Product'
import {
  CreateReportDTO,
  GetAllDropsByReportedCompany
} from '../../types/Report'
import { RecoverUserDto, RecreatePassword, UpdateUserDTO, UserCreationDTO, UserLoginDTO } from '../../types/User'

export type AuthContextType = {
  signUp: (data: UserCreationDTO) => Promise<AxiosResponse<any, any> | any>
  signIn: (data: UserLoginDTO) => Promise<boolean>
  updateUser: (id: string | undefined, data: UpdateUserDTO) => Promise<boolean>
  signOut: () => boolean
  createCompany: (
    data: CompanyCreationDTO
  ) => Promise<{ success: boolean; data: any } | any>
  updateCompany: (
    data: UpdateCompanyDTO
  ) => Promise<AxiosResponse<any, any> | undefined>
  findOneCompany: (
    data: GetOneCompanyDTO
  ) => Promise<AxiosResponse<any, any> | undefined>
  findOneUser: (
    id: string | null
  ) => Promise<AxiosResponse<any, any> | undefined>
  updateUserImage: (
    image: File | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  updateCompanyImage: (
    image: File | undefined,
    companyId: string | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  createProduct: (data: CreateProductDTO) => Promise<boolean | undefined>
  uploadProductImage: (
    image: File | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  getAllUserProducts: (
    marketName: string | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  getOneUserProduct: (
    productId: string | null
  ) => Promise<AxiosResponse<any, any> | undefined>
  updateUserProductData: (
    data: UpdateProductDTO
  ) => Promise<AxiosResponse<any, any> | undefined>
  getCompanyDataByName: (
    name: string | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  createReport: (data: CreateReportDTO) => Promise<boolean | undefined>
  getLimitedProducts: () => Promise<AxiosResponse<any, any> | undefined>
  getProductsBySize: (
    size: string | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  getEvaluationsByCompany: (
    companyId: string | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  createEvaluation: (
    data: CreateEvaluationDTO
  ) => Promise<AxiosResponse<any, any> | undefined>
  getProductsByName: (
    name: string | undefined,
    limit?: number | undefined,
    page?: number | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  getProductsByCustomFilter: (
    data: GetCustomProductFilter
  ) => Promise<AxiosResponse<any, any> | undefined>
  getAllProductsByFilter: (
    data: GetAllProductsFiltered
  ) => Promise<AxiosResponse<any, any> | undefined>
  getAllCompaniesByFilter: (
    data: GetAllCompaniesByFilterDTO
  ) => Promise<AxiosResponse<any, any> | undefined>
  getAllDrops: (data?: DefaultRequestPaginator) => Promise<AxiosResponse<any, any> | undefined>
  getDropById: (id: number) => Promise<AxiosResponse<any, any> | undefined>
  getProductsByDropId: (
    data: GetProductByDropId
  ) => Promise<AxiosResponse<any, any> | undefined>
  getDropByName: (
    data: GetDropByName
  ) => Promise<AxiosResponse<any, any> | undefined>
  getProductSizeByDropId: (
    data: GetProductsSizeByDropIdDTO
  ) => Promise<AxiosResponse<any, any> | undefined>
  deleteDrop: (
    dropId: number | '' | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  updateDrop: (
    data: UpdateDropData
  ) => Promise<AxiosResponse<any, any> | undefined>
  uploadDropImage: (
    image: File | undefined
  ) => Promise<AxiosResponse<any, any> | undefined>
  createDrop: (data: CreateDropDTO) => Promise<AxiosResponse<any, any> | any>
  getAllDropsByReportedCompany: (
    data: GetAllDropsByReportedCompany
  ) => Promise<AxiosResponse<any, any> | undefined>
  recoverUserData: (data: RecoverUserDto) => Promise<AxiosResponse<any, any> | undefined>
  recreatePassword: (data: RecreatePassword) => Promise<AxiosResponse<any, any> | undefined>
  genericGetImage: (imageName: string, endpoint: string) => Promise<string | null>
}

export const AuthContext = createContext<AuthContextType>(null!)
