import { BrowserRouter } from 'react-router-dom'

import './App.css'

import { AuthProvider } from './Contexts/Auth/AuthProvider'
import Indexes from './routes'

import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import AtomChat from './Components/AtomChat'
import ScrollToTop from './utils/ScrollToTop'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthProvider>
        <Navbar />
        <AtomChat />
        <Indexes />
      </AuthProvider>
      <Footer />
    </BrowserRouter>
  )
}

export default App
