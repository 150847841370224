import { SwiperSlide } from 'swiper/react'

import '../../../../styles/style.css'
import SwiperConfig from '../../../../Components/SwiperConfig'
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { AuthContext } from '../../../../Contexts/Auth/AuthContext'
import { Product } from '../../../../types/Product'
import { Link } from 'react-router-dom'
import Item from '../../../../Components/Item'
import fetchImages from '../../../../utils/FetchImages'

export default function Auction() {
  const auth = useContext(AuthContext)
  const wrapperRef = useRef(null)

  const [products, setProducts] = useState<Product[]>([])
  const [productModal, setProductModal] = useState(false)
  const [selectProduct, setSelectProduct] = useState<Product>()

  const [productImages, setProductImages] = useState<any>({})

  const getLastProducts = async () => {
    try {
      const productsList = await auth.getLimitedProducts()
      setProducts(productsList?.data?.results)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getLastProducts()
  }, [])

  useEffect(() => {
    const fetchProductImagesForAll = async () => {
      for await (const product of products) {
        for await (const image of JSON.parse(product.images)) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }
    }

    fetchProductImagesForAll()
  }, [products])

  return (
    <>
      {productModal && (
        <Item
          productModal={productModal}
          setProductModal={setProductModal}
          product={selectProduct}
          wrapperRef={wrapperRef}
        />
      )}

      {products && (
        <section id="auction">
          <div className="container">
            <div className="anor_fn_auction_slider">
              <div className="anor_fn_slider_title">
                <h3 className="fn_title">Últimos Anúncios</h3>
                {/* <a
                  className="view_all"
                  onClick={() => navigate('/anuncios')}
                >
                  <span></span>Ver Todos
                </a> */}
                <Link to={'/anuncios'}>
                  <span></span>Ver Todos
                </Link>
              </div>

              <div className="anor_fn_slider_holder">
                <div className="flickity_slider_wrap">
                  <div className="owl-carousel owl-theme owl-loaded owl-drag">
                    <div className="owl-stage-outer">
                      <div className="owl-stage">
                        {!products[0] && (
                          <p style={{ textAlign: 'center' }}>
                            Não existem anúncios disponíveis
                          </p>
                        )}
                        {products.length > 1 && products.length > 0
                          ? (
                          <SwiperConfig>
                            {products.map(
                              (product, index) =>
                                product?.state === 'available' && (
                                  <SwiperSlide
                                    className="fn__single_item item"
                                    key={index}
                                    onClick={() => {
                                      setProductModal(true)
                                      setSelectProduct(product)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div className="img_holder">
                                      <img
                                        src={productImages ? productImages[JSON.parse(product?.images)[0].split('.')[0]] : null}
                                        width={500}
                                      />
                                    </div>
                                    <div className="product-title">
                                      <h5
                                        className="fn_title"
                                        style={{ marginBottom: '5px' }}
                                      >
                                        <a>{product?.name.length > 27 ? `${product?.name.substring(0, 27)}...` : product?.name}</a>
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: '14px',
                                          margin: '0',
                                          padding: '0',
                                          lineHeight: '1.5',
                                          color: '#777'
                                        }}
                                      >
                                        R$ {product?.price}
                                      </p>
                                      <p
                                        key={index}
                                        style={{
                                          fontSize: '16px',
                                          margin: '0',
                                          padding: '0',
                                          color: '#777',
                                          lineHeight: '1.5'
                                        }}
                                      >
                                        {JSON.parse(product?.sizes!).map(
                                          (
                                            size:
                                              | string
                                              | number
                                              | boolean
                                              | ReactElement<
                                                  any,
                                                  | string
                                                  | JSXElementConstructor<any>
                                                >
                                              | ReactFragment
                                              | ReactPortal
                                              | null
                                              | undefined,
                                            index: Key | null | undefined
                                          ) => (
                                            <span
                                              key={index}
                                              style={{
                                                fontSize: '11px',
                                                display: 'inline-block',
                                                borderRadius: '3px',
                                                color: '#FF5B36',
                                                padding: '0 6px',
                                                backgroundColor: '#EEEEEE',
                                                fontWeight: '600',
                                                marginRight: '3px'
                                              }}
                                            >
                                              {size}
                                            </span>
                                          )
                                        )}
                                      </p>
                                    </div>
                                  </SwiperSlide>
                                )
                            )}
                          </SwiperConfig>
                            )
                          : (
                              products[0] && (
                            <>
                              <div
                                onClick={() => {
                                  setProductModal(true)
                                  setSelectProduct(products[0])
                                }}
                                className="img_holder"
                                style={{ width: '309px' }}
                              >
                                <img
                                  src={productImages ? productImages[JSON.parse(products[0]?.images)[0].split('.')[0]] : null}
                                  width={500}
                                  style={{ borderRadius: '25px' }}
                                />
                              </div>
                              <div className="product-title">
                                <h5
                                  className="fn_title"
                                  style={{ marginBottom: '5px', borderRadius: '25px' }}
                                  onClick={() => {
                                    setProductModal(true)
                                    setSelectProduct(products[0])
                                  }}
                                >
                                  <a>{products[0]?.name.length > 27 ? `${products[0]?.name.substring(0, 27)}...` : products[0]?.name}</a>
                                </h5>
                                <p
                                  style={{
                                    fontSize: '14px',
                                    margin: '0',
                                    padding: '0',
                                    lineHeight: '1.5',
                                    color: '#777'
                                  }}
                                >
                                  R$ {products[0]?.price}
                                </p>
                              </div>
                            </>
                              )
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
