import Auction from './components/Auction'
import Discover from './components/Discover'
import Drops from './components/Drops'
import InYourSize from './components/InYourSize'
import QuickSearch from './components/QuickSearch'

export default function Home() {
  return (
    <>
      <Discover />
      <InYourSize />
      <Drops />
      <QuickSearch />
      <Auction />
    </>
  )
}
