import '../../styles/style.css'

import logo from '../../assets/images/footer-logo.png'

import { AiOutlineTwitter, AiOutlineInstagram } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Footer() {
  const companyName = localStorage.getItem('companyName') || ''

  const navigate = useNavigate()

  return (
    <>
      <footer id="footer">
        <div className="anor_fn_footer">
          <div className="footer_top">
            <div className="container">
              <div className="ft_in">
                <div className="ft_left">
                  <div className="ft_left_in">
                    <div className="logo">
                      <a>
                        <img src={logo} width={130} alt="" />
                      </a>
                    </div>
                    <div className="desc">
                      <p>
                        Conecte-se com outros vendedores com liberdade em suas
                        negociações e além disso tenha uma página exclusiva para
                        sua loja.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="ft_right">
                  <div className="title">
                    <h3 className="fn_title">Atalhos</h3>
                  </div>
                  <div className="link_list">
                    <ul>
                      <li>
                        {/* <a onClick={() => navigate('/product')}>
                          Adicionar Anúncio
                        </a> */}
                        <Link to={'/product'}>Adicionar Anúncio</Link>
                      </li>
                      <li>
                        {/* <a onClick={() => navigate('/meus-anuncios')}>Meus anúncios</a> */}
                        <Link to={'/meus-anuncios'}>Meus anúncios</Link>
                      </li>
                      <li>
                        <a>Políticas</a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            if (companyName) {
                              return navigate(`/l/${companyName}`)
                            }
                            return toast.error(
                              'Entra na sua conta ou crie uma loja para acessar'
                            )
                          }}
                        >
                          Ver Minha Loja
                        </a>
                      </li>
                      <li>
                        <a>Termos de Uso</a>
                      </li>
                      <li>
                        {/* <a onClick={() => navigate('/perfil')}>Meu Perfil</a> */}
                        <Link to={'/perfil'}>Meu Perfil</Link>
                      </li>
                      <li>
                        {/* <a onClick={() => navigate('/about')}>Sobre</a> */}
                        <Link to={'/about'}>Sobre</Link>
                      </li>
                      <li>
                        {/* <a
                          onClick={() =>
                            navigate('/anuncios?type=off-white')
                          }
                        >
                          Off-White
                        </a> */}
                        <Link to={'/anuncios?type=off-white'}>
                          Off-White
                        </Link>
                      </li>
                      <li>
                        {/* <a onClick={() => navigate('/anuncios?type=dunk')}>
                          Dunks
                        </a> */}
                        <Link to={'/anuncios?type=dunk'}>Dunks</Link>
                      </li>
                      <li>
                        {/* <a
                          onClick={() => navigate('/anuncios?type=yeezy')}
                        >
                          Yeezys
                        </a> */}
                        <Link to={'/anuncios?type=yeezy'}>Yeezys</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_bottom">
            <div className="container">
              <div className="fb_in">
                <div className="fb_left">
                  <p>
                    Engineered by{' '}
                    <a
                      style={{ fontWeight: 'bold' }}
                      href="https://www.instagram.com/nenlabs/"
                    >
                      NEN
                    </a>
                  </p>
                </div>

                <div className="fb_right">
                  <div className="social_list">
                    <ul>
                      <li>
                        <a>
                          <AiOutlineTwitter size={30} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/seliganodrop/"
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          <AiOutlineInstagram size={30} />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="totop"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
