import '../../styles/style.css'
import search from '../../assets/images/search.svg'
import { useNavigate } from 'react-router-dom'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DropData } from '../../types/Drop'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { debounce } from 'lodash'
import fetchImages from '../../utils/FetchImages'

export default function SearchBar() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const wrapperRef = useRef(null)

  const [showResults, setShowResults] = useState(false)
  const [searchResults, setSearchResults] = useState<DropData[]>([])
  const [searchKeyword, setSearchKeyword] = useState('')

  const [dropImages, setDropImages] = useState<any>({})

  const getSearchResults = async (keyword: string) => {
    if (keyword.length <= 2) return

    const results = await auth.getDropByName({
      name: keyword,
      limit: 12,
      page: 0
    })
    setSearchResults(results?.data?.results)
  }

  const debouncedGetSearchResults = useCallback(
    debounce((value) => {
      getSearchResults(value)
      setSearchKeyword(value)
    }, 500),
    []
  )

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowResults(false)
          setSearchResults([])
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchstart', handleClickOutside)
      }
    }, [ref])
  }

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') navigate(`/anuncios?name=${searchKeyword}`)
  }

  useOutsideAlerter(wrapperRef)

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const drop of searchResults) {
        await fetchImages(drop.image, '/drop/drop-image/get/', setDropImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [searchResults])

  return (
    <div className="fn_search">
      <input
        type="text"
        id="search_data"
        autoComplete="off"
        className="form-control input-lg"
        placeholder="Procurar"
        onChange={e => debouncedGetSearchResults(e.target.value)}
        onFocus={() => setShowResults(true)}
        ref={wrapperRef}
        onKeyDown={handleKeyDown}
      />
      <img src={search} alt="" className="fn__svg" />

      <ul
        className="search-menu"
        style={
          showResults && searchResults.length > 0
            ? { display: 'block' }
            : { display: 'none' }
        }
      >
        {searchResults.map((product, index) => (
          <li key={index}>
            <a
              onClick={() => {
                setShowResults(false)
                navigate(`/drop/${product?.id}`)
              }}
              ref={wrapperRef}
            >
              <img
                // src={HandleImage('/drop/drop-image/get/', product?.image)}
                src={dropImages
                  ? dropImages[product?.image?.split('.')[0]]
                  : null}
                alt=""
                width={50}
              />
              {product?.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
