import axios from 'axios'
import {
  CompanyCreationDTO,
  GetAllCompaniesByFilterDTO,
  GetOneCompanyDTO,
  UpdateCompanyDTO
} from '../types/Company'
import {
  CreateDropDTO,
  DefaultRequestPaginator,
  GetDropByName,
  GetProductsSizeByDropIdDTO,
  UpdateDropData
} from '../types/Drop'
import { CreateEvaluationDTO } from '../types/Evaluation'
import {
  CreateProductDTO,
  GetAllProductsFiltered,
  GetCustomProductFilter,
  GetProductByDropId,
  UpdateProductDTO
} from '../types/Product'
import { CreateReportDTO, GetAllDropsByReportedCompany } from '../types/Report'
import { RecoverUserDto, RecreatePassword, UpdateUserDTO, UserCreationDTO, UserLoginDTO } from '../types/User'

const userAuthToken = localStorage.getItem('authentication')
const bucketName = 'seliga-files'

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: userAuthToken
  }
})

export const useAPI = () => ({
  signUp: async (data: UserCreationDTO) => {
    const response = await api.post('/users/', data)
    return response
  },

  signIn: async (data: UserLoginDTO) => {
    const response = await api.get(`/users/${data.email}/${data.password}`)
    return response
  },

  updateUser: async (id: string | undefined, data: UpdateUserDTO) => {
    const response = await api.patch(`/users/${id}`, data)
    return response
  },

  findOneUser: async (id: string | null) => {
    const response = await api.get(`/users/${id}`)
    return response
  },

  createCompany: async (data: CompanyCreationDTO) => {
    const response = await api.post('/companies/', data)
    return response
  },

  updateCompany: async (data: UpdateCompanyDTO) => {
    const id = data.companyId
    delete data.companyId
    const response = await api.patch(`/companies/${id}`, data)
    return response
  },

  findOneCompany: async (data: GetOneCompanyDTO) => {
    const response = await api.get(`/companies/${data.id}`)
    return response
  },

  updateUserImage: async (image: File | undefined) => {
    const response = await axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/users/profile-image/upload`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: userAuthToken
      },
      data: { file: image, userId: userAuthToken, bucketName }
    })
    return response
  },

  updateCompanyImage: async (
    image: File | undefined,
    companyId: string | undefined
  ) => {
    const response = await axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/companies/company-image/upload`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: userAuthToken
      },
      data: { file: image, companyId, bucketName }
    })
    return response
  },

  createProduct: async (data: CreateProductDTO) => {
    const response = await api.post('/product/', data)
    return response
  },

  uploadProductImage: async (image: File | undefined) => {
    const response = await axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/product/product-image/upload`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: userAuthToken
      },
      data: { file: image, bucketName }
    })
    return response
  },

  getAllUserProducts: async (marketName: string | undefined) => {
    const response = api.get(`/product/${marketName}`)
    return response
  },

  getOneUserProduct: async (productId: string | null) => {
    const response = await api.get(`/product/getOne/${productId}`)
    return response
  },

  updateUserProductData: async (data: UpdateProductDTO) => {
    const response = await api.patch(`/product/${data.id}`, data)
    return response
  },

  getCompanyDataByName: async (name: string | undefined) => {
    const response = await api.get(`/companies/search/${name}`)
    return response
  },

  createReport: async (data: CreateReportDTO) => {
    const response = await api.post('/report/', data)
    return response
  },

  getLimitedProducts: async () => {
    const response = await api.get('/product/limited')
    return response
  },

  getProductsBySize: async (size: string | undefined) => {
    const response = await api.get(`/product/size/${size}`)
    return response
  },

  getEvaluationsByCompany: async (companyId: string | undefined) => {
    const response = await api.get(`/evaluation/${companyId}`)
    return response
  },

  createEvaluation: async (data: CreateEvaluationDTO) => {
    const response = await api.post('/evaluation', data)
    return response
  },

  getProductsByName: async (
    name: string | undefined,
    limit?: number | undefined,
    page?: number | undefined
  ) => {
    const response = await api.get(
      `/product/name/${name}/${limit || 0}/${page || 0}`
    )
    return response
  },

  getProductsByCustomFilter: async (data: GetCustomProductFilter) => {
    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/product/list`,
      headers: {
        Authorization: userAuthToken
      },
      params: data
    })
    return response
  },

  getAllProductsByFilter: async (data: GetAllProductsFiltered) => {
    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/product/all`,
      headers: {
        Authorization: userAuthToken
      },
      params: data
    })
    return response
  },

  getAllCompaniesByFilter: async (data: GetAllCompaniesByFilterDTO) => {
    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/companies/all`,
      headers: {
        Authorization: userAuthToken
      },
      params: data
    })
    return response
  },

  getAllDrops: async (data?: DefaultRequestPaginator) => {
    const response = await api.get('/drop/', { params: data })
    return response
  },

  getDropById: async (id: number) => {
    const response = await api.get(`/drop/${id}`)
    return response
  },

  getProductsByDropId: async (data: GetProductByDropId) => {
    const { id, limit, page } = data

    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/product/drops/${id}/${limit}/${page}`,
      headers: {
        Authorization: userAuthToken
      },
      params: data
    })
    return response
  },

  getDropByName: async (data: GetDropByName) => {
    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/drop/byName/`,
      headers: {
        Authorization: userAuthToken
      },
      params: data
    })
    return response
  },

  getProductSizeByDropId: async (data: GetProductsSizeByDropIdDTO) => {
    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/product/sizeByDrop/${data?.size}/${data?.dropIdNumber}`,
      headers: {
        Authorization: userAuthToken
      }
    })
    return response
  },

  deleteDrop: async (dropId: number | '' | undefined) => {
    const response = await api.delete(`/drop/${dropId}`)
    return response
  },

  updateDrop: async (data: UpdateDropData) => {
    const response = await api.patch(`/drop/${data.id}`, data)
    return response
  },

  uploadDropImage: async (image: File | undefined) => {
    const response = await axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/drop/drop-image/upload`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: userAuthToken
      },
      data: { file: image, userId: userAuthToken, bucketName }
    })
    return response
  },

  createDrop: async (data: CreateDropDTO) => {
    const response = await api.post('/drop', data)
    return response
  },

  getAllDropsByReportedCompany: async (data: GetAllDropsByReportedCompany) => {
    const response = await axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/report/`,
      headers: {
        Authorization: userAuthToken
      },
      params: data
    })
    return response
  },

  recoverUserData: async (data: RecoverUserDto) => {
    const response = await api.post('/users/recover', data)
    return response
  },

  recreatePassword: async (data: RecreatePassword) => {
    const response = await api.post('/users/recreate-password', data)
    return response
  },

  genericGetImage: async (imageName: string, endpoint: string) => {
    const response = await api.get(`${endpoint}${imageName}`)
    return response?.data
  }
})
