import React, {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useContext,
  useEffect,
  useState
} from 'react'
import { GetUserProductsDTO } from '../../../../types/Product'
import fetchImages from '../../../../utils/FetchImages'
import { AuthContext } from '../../../../Contexts/Auth/AuthContext'

type AnnouncementsProps = {
  setCategory: (value: React.SetStateAction<string | undefined>) => void
  getSearchAnnouncementsResults: (keyword: string) => Promise<void>
  products: GetUserProductsDTO[]
  category: string | undefined
  setEditModal: (value: React.SetStateAction<string>) => void
  editModal: string
  setAnnoucementModal: (value: React.SetStateAction<string>) => void
  setAction: (value: React.SetStateAction<string | undefined>) => void
}

export default function Announcements(props: AnnouncementsProps) {
  const {
    setCategory,
    getSearchAnnouncementsResults,
    products,
    category,
    setEditModal,
    editModal,
    setAnnoucementModal,
    setAction
  } = props

  const auth = useContext(AuthContext)

  const [productImages, setProductImages] = useState<any>({})

  useEffect(() => {
    const fetchProductImagesForAll = async () => {
      for await (const product of products) {
        for await (const image of JSON.parse(product.images)) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }
    }

    fetchProductImagesForAll()
  }, [products])

  return (
    <div
      className="anor_fn_add_item_page"
      id="announcementsacoes"
      style={{ marginBottom: '111px' }}
    >
      <div className="container small" style={{ padding: 0 }}>
        <div className="anor_fn_add_item">
          <div className="field_part">
            <div className="fields_content">
              <ul className="fields">
                <div className="anor_fn_ranking_page" style={{ padding: 0 }}>
                  <div className="container small">
                    <div className="ranking_page">
                      <div className="page_title_holder" style={{ padding: 0 }}>
                        <h3 className="fn_title">Anúncios</h3>
                        <div className="filters">
                          <div className="dd_filter">
                            <div className="input_wrapper">
                              <select
                                name="gender"
                                className="filter_list"
                                onChange={e => setCategory(e.target.value)}
                              >
                                <option
                                  defaultValue={'analysis'}
                                  value={'analysis'}
                                >
                                  Em Análise
                                </option>
                                <option value={'available'}>Ativo</option>
                                <option value={'desactivated'}>
                                  Desativado
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="fn_search">
                          <input
                            type="text"
                            id="search_data"
                            autoComplete="off"
                            className="form-control input-lg"
                            placeholder="Procurar"
                            onChange={e =>
                              getSearchAnnouncementsResults(e.target.value)
                            }
                            style={{ marginBottom: '20px' }}
                          />
                        </div>

                        <div className="anor_fn_items_list">
                          <ul
                            className="anor_fn_list"
                            data-cols="5"
                            data-gap="20"
                          >
                            {products.map(
                              (product, index) =>
                                product.state === category && (
                                  <li key={index}>
                                    <div className="fn__single_item">
                                      <div className="img_holder">
                                        <img
                                          src={productImages ? productImages[JSON.parse(product?.images)[0].split('.')[0]] : null}
                                          style={{
                                            width: '100%',
                                            height: '220px',
                                            objectFit: 'cover'
                                          }}
                                          alt=""
                                        />
                                        <div className="action_box">
                                          <a
                                            className="action_btn"
                                            style={{ bottom: '10px' }}
                                            onClick={() =>
                                              setEditModal(
                                                product.id === editModal
                                                  ? ''
                                                  : product.id
                                              )
                                            }
                                          >
                                            <span
                                              style={
                                                editModal === product.id
                                                  ? {
                                                      transform:
                                                        'rotate(90deg)',
                                                      transition:
                                                        'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                                    }
                                                  : {
                                                      transition:
                                                        'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                                    }
                                              }
                                            ></span>
                                          </a>
                                          <div
                                            className="action_popup"
                                            style={
                                              editModal === product.id
                                                ? {
                                                    opacity: 1,
                                                    visibility: 'visible',
                                                    transform: `translate(${0}px, ${-5}px)`
                                                  }
                                                : {}
                                            }
                                          >
                                            <ul>
                                              <li>
                                                <a
                                                  className="anor_fn_place_item"
                                                  onClick={() => {
                                                    setAnnoucementModal(
                                                      product.id
                                                    )
                                                    setAction('available')
                                                  }}
                                                >
                                                  Aprovar
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="anor_fn_share_item"
                                                  onClick={() => {
                                                    setAnnoucementModal(
                                                      product.id
                                                    )
                                                    setAction('desactivated')
                                                  }}
                                                >
                                                  Desativar
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="title_holder"
                                        style={{ padding: '10px 5px' }}
                                      >
                                        <h3
                                          className="fn_title"
                                          style={{ fontSize: '14px' }}
                                        >
                                          <p
                                            style={{
                                              color: '#000',
                                              fontSize: '0.9rem'
                                            }}
                                          >
                                            {product?.name}
                                          </p>
                                        </h3>
                                        <p
                                          style={{
                                            fontSize: '12px',
                                            display: 'block',
                                            textAlign: 'left'
                                          }}
                                        >
                                          R${product?.price}
                                        </p>
                                        <p
                                          style={{
                                            display: 'block',
                                            textAlign: 'right',
                                            marginTop: '-23px'
                                          }}
                                        >
                                          {JSON.parse(product.sizes!).map(
                                            (
                                              size:
                                                | string
                                                | number
                                                | boolean
                                                | ReactElement<
                                                    any,
                                                    | string
                                                    | JSXElementConstructor<any>
                                                  >
                                                | ReactFragment
                                                | ReactPortal
                                                | null
                                                | undefined,
                                              index: Key | null | undefined
                                            ) => (
                                              <span
                                                key={index}
                                                style={{
                                                  fontSize: '11px'
                                                }}
                                              >
                                                {size}
                                              </span>
                                            )
                                          )}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: '12px',
                                            display: 'block',
                                            textAlign: 'left'
                                          }}
                                        >
                                          {product.createdBy}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
