import { useAPI } from '../../Hooks/useAPI'
import {
  CompanyCreationDTO,
  GetAllCompaniesByFilterDTO,
  GetOneCompanyDTO,
  UpdateCompanyDTO
} from '../../types/Company'
import {
  CreateDropDTO,
  DefaultRequestPaginator,
  GetDropByName,
  GetProductsSizeByDropIdDTO,
  UpdateDropData
} from '../../types/Drop'
import { CreateEvaluationDTO } from '../../types/Evaluation'
import {
  CreateProductDTO,
  GetAllProductsFiltered,
  GetCustomProductFilter,
  GetProductByDropId,
  UpdateProductDTO
} from '../../types/Product'
import {
  CreateReportDTO,
  GetAllDropsByReportedCompany
} from '../../types/Report'
import { RecoverUserDto, RecreatePassword, UpdateUserDTO, UserCreationDTO, UserLoginDTO } from '../../types/User'
import { AuthContext } from './AuthContext'

export const AuthProvider = ({
  children
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  const api = useAPI()

  const saveToken = (token: string) => {
    localStorage.setItem('authentication', token)
  }

  const saveUserData = (userId: string) => {
    localStorage.setItem('user', userId)
  }

  const saveStoreId = (id: string) => {
    if (id) localStorage.setItem('company', id)
  }

  const signUp = async (data: UserCreationDTO) => {
    try {
      const response = await api.signUp(data)
      return response
    } catch (error: any) {
      return error
    }
  }

  const signIn = async (data: UserLoginDTO) => {
    try {
      const response = await api.signIn(data)
      if (response?.status === 200) {
        saveToken(response.data.token)

        delete response.data.password
        delete response.data.role
        delete response.data.cpf
        delete response.data.token

        saveUserData(response.data?.id)
        saveStoreId(response.data?.company)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const updateUser = async (id: string | undefined, data: UpdateUserDTO) => {
    try {
      const response = await api.updateUser(id, data)
      if (response?.status === 200 && response?.data?.affected > 0) return true
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const findOneUser = async (id: string | null) => {
    try {
      const response = await api.findOneUser(id)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const signOut = () => {
    try {
      saveToken('')
      localStorage.clear()
      window.location.reload()
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const createCompany = async (data: CompanyCreationDTO) => {
    try {
      const response = await api.createCompany(data)
      if (response?.status === 201) {
        return { success: true, data: response.data }
      }
      return { success: false, data: '' }
    } catch (error) {
      console.error(error)
      return { success: false, data: error }
    }
  }

  const updateCompany = async (data: UpdateCompanyDTO) => {
    try {
      const response = await api.updateCompany(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const findOneCompany = async (data: GetOneCompanyDTO) => {
    try {
      const response = await api.findOneCompany(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const updateUserImage = async (image: File | undefined) => {
    try {
      const response = await api.updateUserImage(image)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const updateCompanyImage = async (
    image: File | undefined,
    companyId: string | undefined
  ) => {
    try {
      const response = await api.updateCompanyImage(image, companyId)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const createProduct = async (data: CreateProductDTO) => {
    try {
      const response = await api.createProduct(data)
      if (response?.status === 201) return true
      return false
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const uploadProductImage = async (image: File | undefined) => {
    try {
      const response = await api.uploadProductImage(image)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getAllUserProducts = async (marketName: string | undefined) => {
    try {
      const response = await api.getAllUserProducts(marketName)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getOneUserProduct = async (productId: string | null) => {
    try {
      const response = await api.getOneUserProduct(productId)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const updateUserProductData = async (data: UpdateProductDTO) => {
    try {
      const response = await api.updateUserProductData(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getCompanyDataByName = async (name: string | undefined) => {
    try {
      const response = await api.getCompanyDataByName(name)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const createReport = async (data: CreateReportDTO) => {
    try {
      const response = await api.createReport(data)
      if (response?.status === 201) return true
      return false
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getLimitedProducts = async () => {
    try {
      const response = await api.getLimitedProducts()
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getProductsBySize = async (size: string | undefined) => {
    try {
      const response = await api.getProductsBySize(size)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getEvaluationsByCompany = async (companyId: string | undefined) => {
    try {
      const response = await api.getEvaluationsByCompany(companyId)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const createEvaluation = async (data: CreateEvaluationDTO) => {
    try {
      const response = await api.createEvaluation(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getProductsByName = async (
    name: string | undefined,
    limit?: number | undefined,
    page?: number | undefined
  ) => {
    try {
      const response = await api.getProductsByName(name, limit, page)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getProductsByCustomFilter = async (data: GetCustomProductFilter) => {
    try {
      const response = await api.getProductsByCustomFilter(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getAllProductsByFilter = async (data: GetAllProductsFiltered) => {
    try {
      const response = await api.getAllProductsByFilter(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getAllCompaniesByFilter = async (data: GetAllCompaniesByFilterDTO) => {
    try {
      const response = await api.getAllCompaniesByFilter(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getAllDrops = async (data?: DefaultRequestPaginator) => {
    try {
      const response = await api.getAllDrops(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getDropById = async (id: number) => {
    try {
      const response = await api.getDropById(id)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getProductsByDropId = async (data: GetProductByDropId) => {
    try {
      const response = await api.getProductsByDropId(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getDropByName = async (data: GetDropByName) => {
    try {
      const response = await api.getDropByName(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getProductSizeByDropId = async (data: GetProductsSizeByDropIdDTO) => {
    try {
      const response = await api.getProductSizeByDropId(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const deleteDrop = async (dropId: number | '' | undefined) => {
    try {
      const response = await api.deleteDrop(dropId)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const updateDrop = async (data: UpdateDropData) => {
    try {
      const response = await api.updateDrop(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const uploadDropImage = async (image: File | undefined) => {
    try {
      const response = await api.uploadDropImage(image)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const createDrop = async (data: CreateDropDTO) => {
    try {
      const response = await api.createDrop(data)
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const getAllDropsByReportedCompany = async (
    data: GetAllDropsByReportedCompany
  ) => {
    try {
      const response = await api.getAllDropsByReportedCompany(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const recoverUserData = async (data: RecoverUserDto) => {
    try {
      const response = await api.recoverUserData(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const recreatePassword = async (data: RecreatePassword) => {
    try {
      const response = await api.recreatePassword(data)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const genericGetImage = async (imageName: string, endpoint: string) => {
    try {
      const response = await api.genericGetImage(imageName, endpoint)
      return response
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signUp,
        signIn,
        signOut,
        createCompany,
        updateUser,
        findOneCompany,
        findOneUser,
        updateUserImage,
        updateCompanyImage,
        updateCompany,
        createProduct,
        uploadProductImage,
        getAllUserProducts,
        getOneUserProduct,
        updateUserProductData,
        getCompanyDataByName,
        createReport,
        getLimitedProducts,
        getProductsBySize,
        getEvaluationsByCompany,
        createEvaluation,
        getProductsByName,
        getProductsByCustomFilter,
        getAllProductsByFilter,
        getAllCompaniesByFilter,
        getAllDrops,
        getDropById,
        getProductsByDropId,
        getDropByName,
        getProductSizeByDropId,
        deleteDrop,
        updateDrop,
        uploadDropImage,
        createDrop,
        getAllDropsByReportedCompany,
        recoverUserData,
        recreatePassword,
        genericGetImage
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
