import { useContext, useEffect, useRef, useState } from 'react'
// @ts-ignore
import MultiImageInput from 'react-multiple-image-input'
import Select, { MultiValue } from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'
import CurrencyInput from 'react-currency-input-field'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { b64toBlob, filterBase64ImageData } from '../../providers/base64ToBlob'
import { selectDTO } from '../../types/Product'

import '../../styles/style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  getProductSizeRange,
  getSelectBoxLabel,
  getSelectConditionLabel,
  getSelectInHandsLabel,
  getSelectTypeLabel
} from '../../providers/mapProviders'
import { DropData } from '../../types/Drop'
import fetchImages from '../../utils/FetchImages'

const animatedComponents = makeAnimated()

export default function Product() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const crop = {
    unit: '%',
    aspect: 1 / 1,
    width: '100'
  }

  const wrapperRef = useRef(null)

  const userId = localStorage.getItem('user') || ''
  const userCompanyId = localStorage.getItem('company') || ''

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const productId = searchParams.get('productId')
  const companyName = localStorage.getItem('companyName') || ''

  const [images, setImages] = useState([])
  const [name, setName] = useState('')
  const [type, setType] = useState<string | undefined>('')
  const [condition, setCondition] = useState<string | undefined>('')
  const [conditionLevel, setConditionLevel] = useState<string | undefined>('')
  const [sizes, setSizes] = useState<MultiValue<unknown> | undefined>()
  const [inHands, setInHands] = useState<string | undefined>('')
  const [box, setBox] = useState<string | undefined>('')
  const [price, setPrice] = useState<string | undefined>('')
  const [description, setDescription] = useState<string | undefined>('')
  const [drop, setDrop] = useState<number | undefined>()

  const [companyState, setCompanyState] = useState<string | undefined>('')
  const [companyIsVerified, setCompanyIsVerified] = useState<boolean | undefined>(false)
  const [showResults, setShowResults] = useState(false)
  const [searchResults, setSearchResults] = useState<DropData[]>([])

  const [dropImages, setDropImages] = useState<any>({})

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BRL'
  })

  const validateInputs = () => {
    if (companyState !== 'active') {
      toast.error(
        'Loja bloqueada. Para mais informações consulte a equpe de suporte'
      )
      return false
    }

    if (condition === 'used' && (sizes?.length || 0) > 1) {
      toast.error('Apenas 1 tamanho pode ser anunciado em produtos usados')
      return false
    }

    if (condition === 'new' && (sizes?.length || 0) > 3) {
      toast.error('Máximo de 3 tamanhos por anúncio atingido')
      return false
    }

    if (Object.keys(images).length === 0) {
      toast.error('Ao menos uma imagem deve ser enviada')
      return false
    }

    if (!name) {
      toast.error('Digite um nome de produto válido')
      return false
    }

    if (!type) {
      toast.error('Selecione o tipo do produto')
      return false
    }

    if (!condition) {
      toast.error('Selecione a condição do produto')
      return false
    }

    if (condition === 'used' && !conditionLevel) {
      toast.error('Selecione o nível da condição do produto')
      return false
    }

    if (!inHands) {
      toast.error('Selecione se o produto está em mãos')
      return false
    }

    if (!price) {
      toast.error('Digite o valor do produto')
      return false
    }
    return true
  }

  const handleAddProduct = async () => {
    if (validateInputs()) {
      const sizeValues: selectDTO[] | undefined = sizes?.map(
        (size): selectDTO => size as selectDTO
      )
      const filteredSizeValues = sizeValues?.map(data => data?.value)

      const newImageUrls: string[] = []

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(images)) {
        const imageData = filterBase64ImageData(value)

        const blob = await b64toBlob(
          imageData?.imageMetadata,
          imageData?.imageType
        )
        const file = await new File([blob], 'product')
        const uploadResponse = await auth.uploadProductImage(file)
        const path = uploadResponse?.data?.imagePath || ''
        newImageUrls.push(path)
      }

      const saveUserProduct = await auth.createProduct({
        images: JSON.stringify(newImageUrls),
        name,
        type,
        condition,
        conditionLevel,
        sizes: JSON.stringify(filteredSizeValues),
        inHands,
        box,
        price: formatter.format(parseInt(price || '')).replace('R$', ''),
        description,
        state: 'available', // default: companyIsVerified ? 'available' : 'analysis'
        user: userId,
        createdBy: companyName,
        drop,
        dropIdNumber: drop
      })

      if (saveUserProduct) {
        navigate('/meus-anuncios', { replace: true })
        return toast.success('Anúncio criado com sucesso')
      }

      return toast.error('Erro ao criar anúncio. Tente novamente mais tarde')
    }
  }

  const handleUpdateProduct = async () => {
    const updateProduct = await auth.updateUserProductData({
      id: productId,
      name,
      type,
      condition,
      conditionLevel,
      inHands,
      box,
      price,
      drop
    })
    if (updateProduct?.data?.affected > 0) {
      toast.success('Anúncio atualizado')
      return navigate('/meus-anuncios', { replace: true })
    }
  }

  const getProductData = async () => {
    try {
      const productData = await auth.getOneUserProduct(productId)
      setName(productData?.data?.name)
      setType(productData?.data?.type)
      setCondition(productData?.data?.condition)
      setConditionLevel(productData?.data?.conditionLevel)
      setSizes(productData?.data?.sizes)
      setInHands(productData?.data?.inHands)
      setBox(productData?.data?.box)
      setPrice(productData?.data?.price.replace('.00', '').replace(',', ''))
      setDescription(productData?.data?.description)
      setDrop(productData?.data?.drop)
      return productData?.data
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const getCompanyData = async () => {
    try {
      const companyData = await auth.findOneCompany({ id: userCompanyId || '' })
      setCompanyIsVerified(companyData?.data?.verified)
      setCompanyState(companyData?.data?.state)
    } catch (error) {
      console.error(error)
    }
  }

  const getSearchResults = async (keyword: string) => {
    if (keyword.length <= 2) return

    const results = await auth.getDropByName({
      name: keyword,
      limit: 12,
      page: 0
    })
    setSearchResults(results?.data?.results)
  }

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowResults(false)
          setSearchResults([])
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchstart', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  useEffect(() => {
    if (!userId) return navigate('/', { replace: true })
    if (productId) getProductData()
    if (userCompanyId) getCompanyData()
  }, [])

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const drop of searchResults) {
        await fetchImages(drop.image, '/drop/drop-image/get/', setDropImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [searchResults])

  return (
    <>
      <div className="anor_fn_add_item_page">
        <div className="container small">
          <div className="anor_fn_add_item">
            <form id="addproduto" encType="multipart/form-data">
              <div className="field_part">
                <div className="top_title">
                  <h3 className="fn_title">Adicionar Produto</h3>
                </div>

                <div className="fields_content">
                  <ul className="fields">
                    {!productId && (
                      <li className="field">
                        <MultiImageInput
                          max={7}
                          width={450}
                          height={450}
                          theme="light"
                          images={images}
                          setImages={setImages}
                          cropConfig={{
                            crop,
                            ruleOfThirds: true,
                            maxHeight: 800,
                            maxWidth: 800
                          }}
                        />
                      </li>
                    )}
                    <li className="field">
                      <div className="field_item">
                        <label className="label">Nome do Produto</label>
                        <div className="fn_search">
                          <input
                            type="text"
                            id="search_data"
                            placeholder="Air Jordan 1..."
                            autoComplete="off"
                            name="name"
                            className="form-control input-lg"
                            value={name}
                            onChange={e => {
                              setName(e.target.value)
                              getSearchResults(e.target.value)
                            }}
                            onFocus={() => {
                              setShowResults(true)
                              setName('')
                              setDrop(undefined)
                            }}
                            onBlur={() => (drop ? setShowResults(false) : '')}
                            ref={wrapperRef}
                          />

                          <ul
                            className="search-menu"
                            style={
                              showResults && searchResults.length > 0
                                ? {
                                    display: 'block',
                                    opacity: 1,
                                    visibility: 'visible',
                                    zIndex: 9999,
                                    transform: 'scale(1) translateY(0px)'
                                  }
                                : { display: 'none' }
                            }
                          >
                            {searchResults.map((drop, index) => (
                              <li
                                key={index}
                                ref={wrapperRef}
                                onClick={() => {
                                  setDrop(drop?.id)
                                  setName(drop?.name)
                                  setShowResults(false)
                                }}
                              >
                                <a>
                                  <img
                                    // src={HandleImage('/drop/drop-image/get/', drop?.image)}
                                    src={dropImages
                                      ? dropImages[drop?.image?.split('.')[0]]
                                      : null}
                                    alt=""
                                    width={50}
                                  />
                                  {drop?.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item dd_filter">
                        <label className="label">Tipo do Produto</label>
                        <div className="input_wrapper">
                          <Select
                            className="custom_select"
                            placeholder={'Escolha...'}
                            value={{
                              value: type,
                              label: getSelectTypeLabel(type)
                            }}
                            options={[
                              { value: 'sneakers', label: 'Tênis' },
                              { value: 'clothing', label: 'Roupa' },
                              { value: 'acessories', label: 'Acessório' }
                            ]}
                            onChange={e => {
                              setType(e?.value)
                              setSizes([])
                            }}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item dd_filter">
                        <label className="label">Condição</label>
                        <div className="input_wrapper">
                          <Select
                            className="custom_select"
                            placeholder={'Escolha...'}
                            value={{
                              value: condition,
                              label: getSelectConditionLabel(condition)
                            }}
                            options={[
                              { value: 'new', label: 'Novo' },
                              { value: 'used', label: 'Usado' }
                            ]}
                            onChange={e => setCondition(e?.value)}
                          />
                        </div>
                      </div>
                    </li>
                    {condition === 'used' && (
                      <li className="field col4 start" id="estado">
                        <div className="field_item dd_filter">
                          <label className="label">Nível de Condição</label>
                          <div className="input_wrapper">
                            <Select
                              className="custom_select"
                              placeholder={'Escolha...'}
                              value={{
                                value: conditionLevel,
                                label: `${conditionLevel}/10`
                              }}
                              options={[
                                { value: '9.5', label: '9.5/10' },
                                { value: '9', label: '9/10' },
                                { value: '8.5', label: '8.5/10' },
                                { value: '8', label: '8/10' },
                                { value: '7.5', label: '7.5/10' },
                                { value: '7', label: '7/10' }
                              ]}
                              onChange={e => setConditionLevel(e?.value)}
                            />
                          </div>
                        </div>
                      </li>
                    )}
                    <li className="field col4 start">
                      <label className="label">Tamanho(s)</label>
                      <div className="input_wrapper">
                        <Select
                          className="custom_select"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          placeholder={'Escolha...'}
                          options={getProductSizeRange(type)}
                          onChange={e => setSizes(e)}
                          isDisabled={!!productId}
                          value={sizes}
                        />
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item dd_filter">
                        <label className="label">O Produto está em mãos?</label>
                        <div className="input_wrapper">
                          <Select
                            className="custom_select"
                            placeholder={'Escolha...'}
                            value={{
                              value: inHands,
                              label: getSelectInHandsLabel(inHands)
                            }}
                            options={[
                              { value: 'yes', label: 'Sim' },
                              { value: 'no', label: 'Não' }
                            ]}
                            onChange={e => setInHands(e?.value)}
                          />
                        </div>
                      </div>
                    </li>
                    {inHands === 'yes' && (
                      <li className="field col4 start temcaixa" id="temcaixa">
                        <div className="field_item dd_filter">
                          <label className="label">O Produto tem caixa?</label>
                          <div className="input_wrapper">
                            <Select
                              className="custom_select"
                              placeholder={'Escolha...'}
                              options={[
                                { value: 'no-data', label: 'Sem dados' },
                                {
                                  value: 'perfect',
                                  label: 'Sim, está em perfeito estado'
                                },
                                {
                                  value: 'damaged',
                                  label: 'Sim, porém está danificada'
                                },
                                { value: 'no-box', label: 'Não' }
                              ]}
                              value={{
                                value: box,
                                label: getSelectBoxLabel(box)
                              }}
                              onChange={e => setBox(e?.value)}
                            />
                          </div>
                        </div>
                      </li>
                    )}
                    <li className="field col4">
                      <div className="field_item">
                        <label className="label">Preço</label>
                        <CurrencyInput
                          id="preco"
                          name="preco"
                          placeholder="R$"
                          decimalsLimit={2}
                          onValueChange={value => setPrice(value)}
                          value={price}
                        />
                      </div>
                    </li>
                    {companyIsVerified && (
                      <>
                        <li className="field">
                          <div className="field_item">
                            <label className="label">
                              Descrição <span>(Opcional)</span>
                            </label>
                            <textarea
                              id="item_desc"
                              name="descricao"
                              placeholder="O Produto foi comprado na..."
                              value={description}
                              maxLength={339}
                              onChange={e => setDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </li>
                      </>
                    )}
                    <li className="field">
                      <div className="field_item">
                        <div className="links">
                          <a
                            className="anor_fn_button small add"
                            onClick={() =>
                              productId
                                ? handleUpdateProduct()
                                : handleAddProduct()
                            }
                          >
                            {!productId
                              ? 'Adicionar Produto'
                              : 'Editar Produto'}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
