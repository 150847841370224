import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  min-height: 5vh;
  font-size: 25px;
`
export const Radio = styled.input`
  display: none;
`
export const RatingDiv = styled.div`
  cursor: pointer;
`
