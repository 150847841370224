import '../../styles/style.css'
import '../../styles/base.css'

import { Link, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import { AuthContext } from '../../Contexts/Auth/AuthContext'

export default function SignUp() {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [gender, setGender] = useState('')
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [size, setSize] = useState('')

  const inputValidation = () => {
    if (password !== confirmationPassword) {
      toast.error('As senhas devem ser iguais')
      return false
    }
    if (password.length < 8) {
      toast.error('Utilize uma senha com no mínimo 8 dígitos')
      return false
    }
    if (!fullName) {
      toast.error('Preencha o campo com seu nome completo')
      return false
    }
    if (!email) {
      toast.error('Preencha o campo com seu email')
      return false
    }
    if (!cpf) {
      toast.error('Preencha o campo com seu CPF')
      return false
    }
    if (!cellphone) {
      toast.error('Preencha o campo com seu telefone')
      return false
    }
    if (!birthDate) {
      toast.error('Preencha o campo com sua data de nascimento')
      return false
    }
    if (!gender) {
      toast.error('Escolha alguma das opções de gênero')
      return false
    }
    if (!password) {
      toast.error('Preencha o campo com sua senha')
      return false
    }
    if (!confirmationPassword) {
      toast.error('Confirme sua senha no segundo campo')
      return false
    }
    if (!size) {
      toast.error('Escolha um tamanho de calçado')
      return false
    }

    return true
  }

  const handleSignUp = async () => {
    if (inputValidation()) {
      try {
        const parsedSize = Number(size)
        const parsedDate = new Date(birthDate)

        const response = await auth.signUp({
          name: fullName,
          email,
          cpf,
          cellphone,
          birthDate: parsedDate,
          gender,
          password,
          size: parsedSize
        })

        if (response?.status === 201) {
          navigate('/signin', { replace: true })
          // window.location.reload()
          return toast.success('Usuário criado com sucesso')
        }

        if (response?.response?.status === 403) {
          return toast.error(response?.response?.data?.message)
        }

        return toast.error(
          'Erro ao criar cadastrar usuário. Tente novamente mais tarde!'
        )
      } catch (error: any) {
        console.error(error)
        return toast.error(`Erro ${error?.response?.text} ao cadastar usuário`)
      }
    }
  }

  return (
    <>
      <div className="anor_fn_sign_up">
        <div className="container">
          <div className="sign_up">
            <div className="title_holder">
              <h3 className="fn_title">Criar conta</h3>
              <p className="fn_desc">
                Preencha os campos para cadastrar sua conta
              </p>
            </div>
            <form id="criarconta" method="post">
              <div className="form">
                <label className="field">Nome Completo</label>
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder=""
                  onChange={e => setFullName(e.target.value)}
                />

                <label className="field">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="youremail@mail.com"
                  onChange={e => setEmail(e.target.value)}
                />

                <label className="field">CPF</label>
                <InputMask
                  type="text"
                  name="cpf"
                  id="cpf"
                  placeholder=""
                  onChange={e => setCpf(e.target.value)}
                  mask="999.999.999-99"
                />

                <label className="field">Celular</label>
                <InputMask
                  type="text"
                  name="cellphone"
                  id="cellphone"
                  placeholder=""
                  onChange={e => setCellphone(e.target.value)}
                  mask="(99) 99999-9999"
                />

                <label className="field">Data de Nascimento</label>
                <input
                  type="date"
                  name="birthDate"
                  id="birthDate"
                  placeholder=""
                  onChange={e => setBirthDate(e.target.value)}
                />

                <div className="field_item dd_filter">
                  <label className="label">Gênero</label>
                  <div className="input_wrapper">
                    <select
                      name="gender"
                      className="filter_list"
                      onChange={e => setGender(e.target.value)}
                    >
                      <option value="" selected disabled hidden>
                        Gênero
                      </option>
                      <option value={'Masculino'}>Masculino</option>
                      <option value={'Feminino'}>Feminino</option>
                      <option value={'Outro'}>Não desejo informar</option>
                    </select>
                  </div>
                </div>

                <label className="field">Senha</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                />

                <label className="field">Confirme sua senha</label>
                <input
                  id="confirmationPassword"
                  name="confirmationPassword"
                  type="password"
                  onChange={e => setConfirmationPassword(e.target.value)}
                />

                <div className="field_item dd_filter">
                  <label className="label">Qual tamanho você calça?</label>
                  <div className="input_wrapper">
                    <select
                      name="size"
                      className="filter_list"
                      onChange={e => setSize(e.target.value)}
                    >
                      <option selected disabled hidden>
                        Tamanho
                      </option>
                      <option value={'33'}>33</option>
                      <option value={'34'}>34</option>
                      <option value={'35'}>35</option>
                      <option value={'36'}>36</option>
                      <option value={'37'}>37</option>
                      <option value={'38'}>38</option>
                      <option value={'39'}>39</option>
                      <option value={'40'}>40</option>
                      <option value={'41'}>41</option>
                      <option value={'42'}>42</option>
                      <option value={'43'}>43</option>
                      <option value={'44'}>44</option>
                      <option value={'45'}>45</option>
                      <option value={'46'}>46</option>
                      <option value={'47'}>47</option>
                    </select>
                  </div>
                </div>

                <a
                  className="anor_fn_button sign-up-button"
                  onClick={() => handleSignUp()}
                >
                  Criar Conta
                </a>
                <p className="fn_desc">
                  Já tem conta?{' '}
                  {/* <a onClick={() => navigate('/signin')}>Acessar conta</a> */}
                  <Link to={'/signin'}>Acessar conta</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
