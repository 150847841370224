import { clothingSizes, shoeSizes } from '../constants/sizes'

export const getProductSizeRange = (type: string | undefined) => {
  const sizeTypeMap = {
    clothing: clothingSizes,
    sneakers: shoeSizes,
    acessories: clothingSizes
  }

  return sizeTypeMap[type as keyof typeof sizeTypeMap]
}

export const getSelectTypeLabel = (type: string | undefined) => {
  const typeMap = {
    clothing: 'Roupa',
    sneakers: 'Tênis',
    acessories: 'Acessórios'
  }
  return typeMap[type as keyof typeof typeMap]
}

export const getSelectOrderLabel = (order: string | undefined) => {
  const orderMap = {
    recent: 'Mais Recentes',
    lowestToBigger: 'Preço: Menor para o Maior',
    biggerToLowest: 'Preço: Maior para o Menor'
  }
  return orderMap[order as keyof typeof orderMap]
}

export const productStateMapper = (state: string) => {
  const stateMap = {
    disp: 'available',
    vend: 'sold'
  }
  return stateMap[state as keyof typeof stateMap]
}

export const getSelectConditionLabel = (condition: string | undefined) => {
  const conditionMap = {
    new: 'Novo',
    used: 'Usado'
  }
  return conditionMap[condition as keyof typeof conditionMap]
}

export const getSelectInHandsLabel = (inHands: string | undefined) => {
  const inHandsMap = {
    yes: 'Sim',
    no: 'Não'
  }
  return inHandsMap[inHands as keyof typeof inHandsMap]
}

export const getSelectBoxLabel = (box: string | undefined) => {
  const boxMap = {
    'no-data': 'Sem dados',
    perfect: 'Sim, está em perfeito estado',
    damaged: 'Sim, porém está danificada',
    'no-box': 'Não'
  }
  return boxMap[box as keyof typeof boxMap]
}

export const getAnnouncementBoxState = (box: string | undefined) => {
  const boxMap = {
    'no-data': 'Sem dados',
    perfect: 'Perfeito estado',
    damaged: 'Danificada',
    'no-box': 'Não'
  }
  return boxMap[box as keyof typeof boxMap]
}
