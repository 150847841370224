import { SetStateAction, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import '../../styles/style.css'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { getFileExtension } from '../../utils/FileExtension'
import axios from 'axios'
// @ts-ignore
import MultiImageInput from 'react-multiple-image-input'
import { b64toBlob, filterBase64ImageData } from '../../providers/base64ToBlob'
import { fetchSingleImage } from '../../utils/FetchImages'

export default function Company() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const [userId, setUserId] = useState<string | undefined>('')
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const [instagram, setInstagram] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [images, setImages] = useState([])
  const [companyId, setCompanyId] = useState<string | undefined>('')

  const [isReadyOnly, SetIsReadOnly] = useState(false)

  const userBaseData = localStorage.getItem('user') || ''
  const localCompanyId = localStorage.getItem('company') || ''

  const inputValidation = () => {
    if (!name) {
      toast.error('Nome inválido')
      return false
    }
    if (!link) {
      toast.error('Link da loja inválido')
      return false
    }
    if (!instagram) {
      toast.error('@ do Instagram inválido')
      return false
    }
    if (!whatsapp) {
      toast.error('Whatsapp inválido')
      return false
    }
    if (whatsapp.includes('_')) {
      toast.error('Número de telefone inválido')
      return false
    }
    if (!userId) {
      toast.error(
        'É necessário um login ativo na plataforma para o registro de loja'
      )
      return false
    }
    if (link.includes(' ') || instagram.includes(' ')) {
      toast.error('Espaço no link da loja ou instagram')
      return false
    }
    return true
  }

  const handleCreateCompany = async () => {
    if (inputValidation()) {
      let imagePath = ''

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(images)) {
        const imageData = filterBase64ImageData(value)

        const blob = b64toBlob(
          imageData?.imageMetadata,
          imageData?.imageType
        )
        const file = new File([blob], imageData?.imageType.split('/')[1] || '')
        const uploadResponse = await auth.updateCompanyImage(file, companyId)
        imagePath = uploadResponse?.data?.imagePath || ''
      }

      const { success, data } = await auth.createCompany({
        name,
        link,
        instagram,
        whatsapp,
        image: imagePath,
        user: userId,
        companyId
      })

      if (success) {
        await auth.updateUser(userId, { company: data?.id })
        localStorage.setItem('company', data?.id)
        navigate('/', { replace: true })
        return toast.success('Loja criada com sucesso')
      }

      if (data?.response?.status === 403)
        return toast.error(data?.response?.data?.message)

      return toast.error('Erro ao criar loja. Tente novamente mais tarde')
    }
  }

  const handleUpdateCompany = async () => {
    if (inputValidation()) {
      let imagePath = ''

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(images)) {
        const imageData = filterBase64ImageData(value)

        const blob = b64toBlob(
          imageData?.imageMetadata,
          imageData?.imageType
        )
        const file = new File([blob], imageData?.imageType.split('/')[1] || '')
        const uploadResponse = await auth.updateCompanyImage(file, companyId)
        imagePath = uploadResponse?.data?.imagePath || ''
      }

      const responseData = await auth.updateCompany({
        name,
        link,
        instagram,
        whatsapp,
        user: userId,
        image: imagePath,
        companyId
      })

      if (responseData?.data?.affected > 0)
        return toast.success('Dados atualizados')

      return toast.error('Erro ao atualizar dados. Tente novamente mais tarde')
    }
  }

  const loadCompanyData = async () => {
    const companyData = await auth.findOneCompany({ id: localCompanyId })
    const userProfileImage = await fetchSingleImage(companyData?.data?.image, '/companies/company-image/get/', auth)

    handleImageMetadata(userProfileImage || '')
    setInstagram(companyData?.data?.instagram)
    setLink(companyData?.data?.link)
    setName(companyData?.data?.name)
    setWhatsapp(companyData?.data?.whatsapp)
    setCompanyId(companyData?.data?.id)
  }

  const handleImageMetadata = async (imageUrl: string) => {
    const fileExtension = getFileExtension(imageUrl)

    const imageData = await axios.get(imageUrl, { responseType: 'blob' })
    const reader = new FileReader()
    reader.readAsDataURL(imageData.data)
    reader.onloadend = () => {
      const base64String = reader.result?.toString().split(',')[1]
      setImages({ 0: `data:image/${fileExtension};base64,${base64String}` } as any)
    }
  }

  useEffect(() => {
    setUserId(localStorage.getItem('user') || undefined)
    setCompanyId(localStorage.getItem('company') || undefined)

    loadCompanyData()

    if (companyId) SetIsReadOnly(true)
  }, [userBaseData])

  return (
    <>
      <div className="anor_fn_add_item_page" style={{ marginBottom: '60px' }}>
        <div className="container small">
          <div className="anor_fn_add_item">
            {/* Fields */}
            <div className="field_part">
              <div className="top_title">
                <h3 className="fn_title">
                  {companyId ? 'Editar Loja' : 'Criar Loja'}
                </h3>
              </div>

              <div className="fields_content">
                <form
                  id="editarloja"
                  method="post"
                  encType="multipart/form-data"
                >
                  <ul className="fields">
                    <li className="field">
                      <div className="field_item">
                        <label className="label">Nome da Loja</label>
                        {/* <span style={{fontSize: '14px'}}>ATENÇÃO: O nome e link só podem ser alterados a cada 15 dias</span> */}
                        <input
                          style={{ color: companyId ? 'gray' : '' }}
                          readOnly={isReadyOnly}
                          id="item_title"
                          name="name"
                          type="text"
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="field col4">
                      <div className="field_item">
                        <label className="label">Link da Loja</label>
                        <input
                          style={{ color: companyId ? 'gray' : '' }}
                          readOnly={isReadyOnly}
                          type="text"
                          name="link"
                          id="texto-digitado"
                          value={link}
                          onChange={e => setLink(e.target.value)}
                        />
                        <span style={{ fontSize: '14px' }}>
                          <div id="exibir-texto">
                            {link &&
                              `${window.location.href.replace(
                                'company',
                                ''
                              )}l/${link}`}
                          </div>
                        </span>
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item">
                        <label className="label">Instagram da Loja</label>
                        <input
                          id="item_title"
                          name="instagram"
                          type="text"
                          value={instagram}
                          onChange={e => setInstagram(e.target.value)}
                        />
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item">
                        <label className="label">WhatsApp</label>
                        <InputMask
                          id="celular"
                          name="whatsapp"
                          type="text"
                          value={whatsapp}
                          onChange={(e: {
                            target: { value: SetStateAction<string> }
                          }) => setWhatsapp(e.target.value)}
                          mask="(99) 99999-9999"
                        />
                      </div>
                    </li>
                    {companyId && (
                      <li className="field col4 start">
                        <div className="field_item" style={{ maxWidth: '215px' }}>
                          <label className="label">Foto da Loja</label>
                          <MultiImageInput
                            max={1}
                            width={450}
                            height={450}
                            theme="light"
                            images={images}
                            setImages={setImages}
                            cropConfig={{
                              crop: {
                                unit: '%',
                                aspect: 1 / 1,
                                width: '100'
                              },
                              ruleOfThirds: true
                            }}
                          />
                        </div>
                      </li>
                    )}

                    <li className="field">
                      <p></p>
                      <div className="field_item">
                        <div className="links">
                          <a
                            className="anor_fn_button small add"
                            onClick={() =>
                              !companyId
                                ? handleCreateCompany()
                                : handleUpdateCompany()
                            }
                          >
                            Salvar Alterações
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
