import moment from 'moment'
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { BsCheckLg } from 'react-icons/bs'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import '../../styles/style.css'
import { Product } from '../../types/Product'
import cancel from '../../assets/images/cancel.svg'
import { toast } from 'react-toastify'
import Rating from '../../Components/Rating'
import { Evaluation } from '../../types/Evaluation'
import { productStateMapper } from '../../providers/mapProviders'
import Item from '../../Components/Item'
import fetchImages, { fetchSingleImage } from '../../utils/FetchImages'

export default function Market() {
  const auth = useContext(AuthContext)
  const wrapperRef = useRef(null)

  const userId = localStorage.getItem('user') || ''
  const companyId = localStorage.getItem('company') || ''
  const companyUserName = localStorage.getItem('companyName') || ''

  const { marketName } = useParams()

  const [marketOptions, setMarketOptions] = useState(false)
  const [label, setLabel] = useState('disp')
  const [section, setSection] = useState('disp')
  const [products, setProducts] = useState<Product[]>([])
  const [reportModal, setReportModal] = useState(false)

  const [externalCompanyId, setExternalCompanyId] = useState<
    string | undefined
  >('')
  const [name, setName] = useState<string | undefined>('')
  const [image, setImage] = useState<string | undefined>('')
  const [date, setDate] = useState<number | string>('')
  const [instagram, setInstagram] = useState<string | undefined>('')
  const [whatsapp, setWhatsapp] = useState<string | undefined>('')
  const [verified, setVerified] = useState<boolean | undefined>(false)
  const [companyLink, setCompanyLink] = useState<string | undefined>('')

  const [report, setReport] = useState<string>('')
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState(0)
  const [evaluations, setEvaluations] = useState<Evaluation[]>([])
  const [testimony, setTestimony] = useState('')

  const [productModal, setProductModal] = useState(false)
  const [selectProduct, setSelectProduct] = useState<Product>()

  const [productImages, setProductImages] = useState<any>({})

  const getLabelStyle = (label: string) => {
    const labelStyleMap = {
      disp: { left: '8%', width: '26%', top: '55px', bottom: 'auto' },
      vend: { left: '37%', width: '26%', top: '55px', bottom: 'auto' },
      avali: { left: '66%', width: '26%', top: '55px', bottom: 'auto' }
    }
    return labelStyleMap[label as keyof typeof labelStyleMap]
  }

  const getUserProducts = async () => {
    try {
      const userProducts = await auth.getAllUserProducts(marketName)
      setProducts(userProducts?.data)
    } catch (error) {
      console.error(error)
    }
  }

  const getCompanyData = async () => {
    try {
      const companyData = await auth.getCompanyDataByName(marketName)
      const userProfileImage = await fetchSingleImage(companyData?.data?.image, '/companies/company-image/get/', auth)
      setName(companyData?.data?.name)
      setImage(userProfileImage as string)
      setDate(moment(companyData?.data?.createdAt).year() || '')
      setVerified(companyData?.data?.verified)
      setCompanyLink(companyData?.data?.link)
      setExternalCompanyId(companyData?.data?.id)
      setInstagram(`https://instagram.com/${companyData?.data?.instagram}`)
      setWhatsapp(
        `https://wa.me/${companyData?.data?.whatsapp.replace(/[^\w\s]+/gi, '')}`
      )
    } catch (error) {
      console.error(error)
    }
  }

  const getEvaluationsByCompanyName = async () => {
    try {
      const evaluationsData = await auth.getEvaluationsByCompany(marketName)
      setEvaluations(evaluationsData?.data)
    } catch (error) {
      console.error(error)
    }
  }

  const validateReportData = () => {
    if (!report) {
      toast.error('Selecione a causa da denúncia')
      return false
    }
    if (!userId) {
      toast.error('Apenas usuários logados podem enviar avaliações')
      return false
    }
    if (!companyId) {
      toast.error('Dados da loja inválidos')
      return false
    }
    return true
  }

  const handleSendReport = async () => {
    try {
      if (validateReportData()) {
        const createReport = await auth.createReport({
          report,
          reportedBy: userId,
          reportedCompany: companyId,
          user: userId,
          company: companyId
        })

        if (createReport) {
          setReportModal(false)
          setMarketOptions(false)
          return toast.success('Denúncia enviada')
        }

        return toast.error(
          'Erro ao enviar denúncia. Tente novamente mais tarde'
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  const validateEvaluationData = () => {
    if (!rating) {
      toast.error('Selecione o número de estrelas devido')
      return false
    }
    if (!testimony) {
      toast.error('Deixe seu depoimento na avaliação')
      return false
    }
    if (!userId) {
      toast.error('Apenas usuários logados podem denúnciar')
      return false
    }
    if (!companyId) {
      toast.error('Dados da loja inválidos')
      return false
    }
    if (!externalCompanyId) {
      toast.error(
        'Não foi possível coletar os dados da loja. Tente novamente mais tarde'
      )
      return false
    }
    return true
  }

  const handleCreateEvaluation = async () => {
    try {
      if (companyLink === companyUserName)
        return toast.info('Não é possível avaliar sua própria loja')

      if (validateEvaluationData()) {
        const evaluationData = await auth.createEvaluation({
          rating,
          testimony,
          evaluatedBy: userId,
          evaluatedCompany: companyLink,
          user: userId,
          company: externalCompanyId
        })

        if (evaluationData?.data)
          return toast.success('Depoimento enviado com sucesso')

        return toast.error('Erro ao enviar depoimento')
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (marketName) getUserProducts()
    if (marketName) getCompanyData()
    if (companyId) getEvaluationsByCompanyName()
  }, [])

  useEffect(() => {
    const fetchProductImagesForAll = async () => {
      for await (const product of products) {
        for await (const image of JSON.parse(product.images)) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }
    }

    fetchProductImagesForAll()
  }, [products])

  return (
    <>
      {productModal && (
        <Item
          productModal={productModal}
          setProductModal={setProductModal}
          product={selectProduct}
          wrapperRef={wrapperRef}
        />
      )}

      <div
        className="anor_fn_modal report_box"
        style={
          reportModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in">
          <div className="modal_closer">
            <img src={cancel} alt="" className="fn__svg" />
          </div>
          <div className="modal_closer">
            <img
              src="svg/cancel.svg"
              alt=""
              className="fn__svg"
              onClick={() => setReportModal(false)}
            />
          </div>
          <div className="modal_title">Denúncia</div>
          <div className="modal_content">
            <p className="fn_desc">Causa da denúncia</p>
            <select
              name="report"
              onChange={e => setReport(e.target.value)}
              value={report}
            >
              <option disabled>Selecione o motivo</option>
              <option value="Loja falsa ou possivelmente scan">
                Loja falsa ou possivelmente scan
              </option>
              <option value="Conteúdo explicíto ou sensível">
                Conteúdo explicíto ou sensível
              </option>
              <option value="Spam">Spam</option>
              <option value="Loja roubada">Loja roubada</option>
              <option value="Outro">Outro</option>
            </select>
            <div className="clearfix"></div>
            <input
              onClick={() => handleSendReport()}
              type="submit"
              value="Denunciar"
            />
          </div>
        </div>
      </div>

      <div className="anor_fn_collectionsingle">
        <div className="anor_fn_collection_header">
          <div className="container small">
            <div className="coll_header">
              <div className="left_part">
                <div className="fn__collection_info">
                  <div className="author_img">
                    {image && (
                      <img
                        src={image}
                        alt=""
                      />
                    )}
                    {verified && (
                      <span
                        className="icon tippy_right"
                        data-tippy-content="Loja Verificada"
                        aria-describedby="tippy-1"
                      >
                        <BsCheckLg className="fn__svg" />
                      </span>
                    )}
                  </div>
                  <div className="author_info">
                    <h3 className="fn_title">{name}</h3>
                    <p>Membro desde {date}</p>
                  </div>
                </div>
              </div>

              <div className="right_part">
                <a className="anor_fn_follow" data-id="">
                  <span className="text">Enviar Mensagem</span>
                </a>
                <div className="action_collection">
                  <a
                    className="action_btn"
                    onClick={() => setMarketOptions(!marketOptions)}
                  >
                    <span></span>
                  </a>
                  <div
                    className="action_popup"
                    style={
                      marketOptions
                        ? {
                            opacity: 1,
                            visibility: 'visible',
                            transform: `translate(${0}px, ${-5}px)`
                          }
                        : {}
                    }
                  >
                    <ul>
                      <li>
                        <a
                          className="anor_fn_report_item"
                          onClick={() => setReportModal(true)}
                        >
                          Denunciar
                        </a>
                      </li>
                      {verified && (
                        <>
                          <li>
                            <a href={whatsapp} target={'_blank'} rel="noreferrer">
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a href={instagram} target={'_blank'} rel="noreferrer">
                              Instagram
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="anor_fn_tabs">
          {/* Tab Filter */}
          <div className="tab_filter_wrapper">
            <div className="tab_filter">
              <ul>
                <li className="current" id="disp">
                  <a
                    onMouseEnter={() => {
                      setLabel('disp')
                    }}
                    onClick={() => setSection('disp')}
                    ref={el => {
                      // eslint-disable-next-line no-useless-return
                      if (!el) return
                    }}
                  >
                    <span className="text">Disponíveis</span>
                  </a>
                </li>
                <li id="vend">
                  <a
                    onMouseEnter={() => {
                      setLabel('vend')
                    }}
                    onClick={() => setSection('vend')}
                  >
                    <span className="text">Vendidos</span>
                  </a>
                </li>
                <li id="avali">
                  <a
                    onMouseEnter={() => {
                      setLabel('avali')
                    }}
                    onClick={() => setSection('avali')}
                  >
                    <span className="text">Avaliações</span>
                  </a>
                </li>
              </ul>
              <span className="ccc" style={getLabelStyle(label)}></span>
            </div>
          </div>

          {section !== 'avali' && (
            <div className="tab_content">
              <div className="tab_list">
                <ul
                  className="anor_fn_list"
                  data-cols="5"
                  data-gap="20"
                  data-vspace="40"
                >
                  {products.map(
                    (product, index) =>
                      product?.state === productStateMapper(section) && (
                        <li key={index}>
                          <div className="fn__single_item">
                            <div className="img_holder">
                              <a
                                onClick={() => {
                                  setProductModal(true)
                                  setSelectProduct(product)
                                }}
                                className="full_link"
                              ></a>
                              <img
                                src={productImages ? productImages[JSON.parse(product?.images)[0].split('.')[0]] : null}
                                style={{
                                  width: '100%',
                                  height: '220px',
                                  objectFit: 'cover'
                                }}
                                alt=""
                              />
                            </div>
                            <div
                              className="title_holder"
                              style={{ padding: '10px 5px' }}
                              id={section}
                            >
                              <h3
                                className="fn_title"
                                style={{ fontSize: '14px' }}
                              >
                                <a>{product?.name}</a>
                              </h3>
                              <p
                                style={{
                                  fontSize: '12px',
                                  display: 'block',
                                  textAlign: 'left'
                                }}
                              >
                                R${product?.price}
                              </p>
                              <p
                                style={{
                                  display: 'block',
                                  textAlign: 'right',
                                  marginTop: '-23px'
                                }}
                              >
                                {JSON.parse(product?.sizes!).map(
                                  (
                                    size:
                                      | string
                                      | number
                                      | boolean
                                      | ReactElement<
                                          any,
                                          string | JSXElementConstructor<any>
                                        >
                                      | ReactFragment
                                      | ReactPortal
                                      | null
                                      | undefined,
                                    index: Key | null | undefined
                                  ) => (
                                    <span
                                      key={index}
                                      style={{ fontSize: '11px' }}
                                    >
                                      {size}
                                    </span>
                                  )
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
          )}

          {section === 'avali' && (
            <div
              className="anor_fn_add_item_page"
              id="avaliacoes"
              style={{ marginBottom: '50px' }}
            >
              <div className="container small">
                <div className="anor_fn_add_item">
                  <div className="field_part">
                    <div className="fields_content">
                      <ul className="fields">
                        <li className="field">
                          <div id="user-rating-form">
                            <Rating
                              rate={rating}
                              setRating={setRating}
                              disabled={true}
                            />
                          </div>
                          <div className="field_item">
                            <textarea
                              id="item_desc"
                              name="descricao"
                              placeholder="Deixe o seu depoimento"
                              onChange={e => setTestimony(e.target.value)}
                            ></textarea>
                          </div>
                        </li>
                        <li className="field">
                          <div className="field_item">
                            <div className="links">
                              <a
                                className="anor_fn_button small add"
                                onClick={() => handleCreateEvaluation()}
                              >
                                Enviar Avaliação
                              </a>
                            </div>
                          </div>
                        </li>

                        <li className="field">
                          <h5 style={{ textAlign: 'left' }}>Avaliações</h5>
                        </li>

                        {evaluations.map((evaluation, index) => (
                          <li className="field" key={index}>
                            <div id="user-rating-form">
                              <Rating
                                rate={evaluation?.rating}
                                disabled={false}
                              />
                            </div>
                            <div className="field_item">
                              <textarea
                                value={evaluation?.testimony}
                                disabled={true}
                                style={{ resize: 'none', border: '0px' }}
                              ></textarea>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
