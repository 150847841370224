import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Switch from '../../Components/Switch'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { filterSizeList } from './utils/FillterSizeList'
import '../../styles/style.css'
import { DropData } from '../../types/Drop'
import { Product } from '../../types/Product'
import Sizes from './components/sizes'

import bg from '../../assets/images/discoverBg.svg'
import Item from '../../Components/Item'
import fetchImages from '../../utils/FetchImages'

export default function Drop() {
  const auth = useContext(AuthContext)
  const wrapperRef = useRef(null)

  const { id } = useParams()

  const [drop, setDrop] = useState<DropData>()
  const [products, setProducts] = useState<Product[]>([])
  const [rawProducts, setRawProducts] = useState<Product[]>([])

  const [productModal, setProductModal] = useState(false)
  const [selectProduct, setSelectProduct] = useState<Product>()
  const [category, setCategory] = useState(false)

  const [announcementModal, setAnnouncementModal] = useState(false)
  const [selectAnnouncement, setSelectAnnouncement] = useState<Product>()

  const [dropImages, setDropImages] = useState<any>({})

  const getDropData = async () => {
    const dropData = await auth.getDropById(parseInt(id || ''))
    setDrop(dropData?.data)
  }

  const getProductsData = async () => {
    const productsData = await auth.getProductsByDropId({
      id: parseInt(id || ''),
      limit: 100,
      page: 0
    })

    const filteredProductList = filterSizeList(productsData?.data?.results)
    setProducts(filteredProductList)
    setRawProducts(productsData?.data?.results)
  }

  const selectRawProductData = (filteredProduct: Product) => {
    const [product] = rawProducts.filter((rawProduct: Product) => rawProduct.id === filteredProduct.id)
    return product
  }

  useEffect(() => {
    if (id) {
      getDropData()
      getProductsData()
    }
  }, [])

  useEffect(() => {
    if (id) {
      const fetchDropImagesForAll = async () => {
        await fetchImages(drop?.image as string, '/drop/drop-image/get/', setDropImages, auth)
      }

      fetchDropImagesForAll()
    }
  }, [])

  return (
    <>
      {productModal && (
        <Sizes
          itemModal={productModal}
          setItemModal={setProductModal}
          product={selectProduct}
        />
      )}

      {announcementModal && (
        <Item
          productModal={announcementModal}
          setProductModal={setAnnouncementModal}
          product={selectAnnouncement}
          wrapperRef={wrapperRef}
        />
      )}

      <div className="drop_background">
        <img src={bg} alt=""/>
      </div>

      <div className="anor_fn_product_page">
        <div className="anor_fn_product">
          <div className="container small">
            <div className="anor_fn_product_info">
              <div className="product_left">
                <div className="product_img">
                  <div className="img_holder">
                    <img
                      // src={HandleImage('/drop/drop-image/get/', drop?.image as string)}
                      src={dropImages && drop?.image
                        ? dropImages[drop?.image?.split('.')[0]]
                        : null}
                      alt=""
                    />
                  </div>
                </div>
                <div className="product_info">
                  <div className="product_desc">
                    <h3
                      className="fn_title"
                      style={{
                        textAlign: 'center',
                        marginBottom: '0'
                      }}
                    >
                      {drop?.name}
                    </h3>
                  </div>
                  <div className="product_table_list">
                    <table>
                      <tbody style={{ textAlign: 'center' }}>
                      <tr style={{ padding: '0' }}>
                        <td style={{ width: '50%' }}>RETAIL</td>
                        <td
                          style={{
                            fontWeight: '600',
                            color: '#222',
                            width: '50%'
                          }}
                        >
                          LANÇAMENTO
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: '18px',
                            padding: '3px 20px 3px 0',
                            lineHeight: '1.5',
                            color: '#777',
                            fontWeight: 'normal'
                          }}
                        >
                          R$ {drop?.retail || '#######'}
                        </td>
                        <td>{drop?.launch || '##/##/####'}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="product_right">
                <div style={{
                  textAlign: 'center',
                  maxWidth: '600px'
                }}>
                  <h4>Anúncios</h4>
                </div>

                <div
                  className="anor_fn_accordion"
                  style={{
                    maxWidth: '600px',
                    minHeight: '53%',
                    backgroundColor: '#f4f4f4'
                  }}
                >
                  <div className="accordion_content">
                    <div className="acc_cont">
                      <div className="anor_fn_product_offers">
                        <div
                          className="offer_content"
                          style={{
                            textAlign: 'center',
                            width: '80%',
                            justifyContent: 'center',
                            position: 'relative',
                            right: '-9.2%'
                          }}
                        >
                          <div className="drop_switch">
                            <Switch
                              category={category}
                              setCategory={setCategory}
                            />
                          </div>

                          {products.map(
                            (product, index) =>
                              product?.condition ===
                              (category ? 'used' : 'new') && (
                                <div
                                  className="offer_item"
                                  key={index}
                                  style={{ padding: '20px 20px' }}
                                >
                                  <div style={{ width: '24%' }}>
                                    <div
                                      className="fn__price_rate"
                                      style={{ display: 'flex' }}
                                    >
                                      <div
                                        key={index}
                                        style={{
                                          backgroundColor: '#FF8743',
                                          width: '30px',
                                          color: '#111',
                                          left: '0',
                                          borderRadius: '20%',
                                          marginRight: '1%'
                                        }}
                                      >
                                        {product?.sizes}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="item item_price">
                                    <div
                                      className="fn__price_rate"
                                      style={{ fontWeight: 500 }}
                                    >
                                      <a
                                        onClick={() => {
                                          setProductModal(true)
                                          setSelectProduct(selectRawProductData(product))
                                        }}
                                        className="announcements_button"
                                        style={{
                                          fontWeight: '500',
                                          padding: '5px',
                                          textDecoration: 'none',
                                          borderRadius: '11%'
                                        }}
                                      >
                                        Mais Anúncios
                                      </a>
                                    </div>
                                  </div>
                                  <div className="item item_price">
                                    <div className="fn__price_rate">
                                      <h3 className="fn_title">
                                        R$ {product?.price}
                                      </h3>
                                    </div>
                                  </div>
                                  <div
                                    className="item item_expiration"
                                    style={{ width: '20%' }}
                                  >
                                    <a
                                      style={{
                                        borderRadius: '20%',
                                        fontWeight: '500'
                                      }}
                                      onClick={() => {
                                        setAnnouncementModal(true)
                                        setSelectAnnouncement(selectRawProductData(product))
                                      }}
                                    >
                                      VER
                                    </a>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
