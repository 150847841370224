import React, { useCallback, useContext, useEffect } from 'react'
import { UserDataDTO } from '../../types/User'
import { GetOneCompanyDTO } from '../../types/Company'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { fetchSingleImage } from '../../utils/FetchImages'

function AtomChat() {
  const auth = useContext(AuthContext)

  const userId = localStorage.getItem('user') || ''
  const userCompanyId = localStorage.getItem('company') || ''

  const loadChat = useCallback(async () => {
    const {
      REACT_APP_ATOMCHAT_CHAT_APP_ID,
      REACT_APP_ATOMCHAT_CHATAUTH
    } = process.env

    if (!userId && !userCompanyId) return

    const company: GetOneCompanyDTO = await getCompanyData()
    const user: UserDataDTO = await getUserData()

    const chatName = company?.name || user?.name
    const chatAvatar = await handleUserImage(company, user)
    const chatLink = company?.link || ''

    const script1 = document.createElement('script')
    script1.innerHTML = `
      var chat_appid = '${REACT_APP_ATOMCHAT_CHAT_APP_ID}';
      var chat_auth = '${REACT_APP_ATOMCHAT_CHATAUTH}';
      var chat_id = '${user.id}';
      var chat_name = '${chatName}';
      var chat_avatar = '${chatAvatar}';
      var chat_link = '${chatLink}';
    `
    document.body.appendChild(script1)

    const script2 = document.createElement('script')
    script2.innerHTML = `
      (function() {
        var chat_css = document.createElement('link');
        chat_css.rel = 'stylesheet';
        chat_css.type = 'text/css';
        chat_css.href = 'https://fast.cometondemand.net/' + chat_appid + 'x_xchat.css';
        document.getElementsByTagName("head")[0].appendChild(chat_css);
      
        var chat_js = document.createElement('script');
        chat_js.type = 'text/javascript';
        chat_js.src = 'https://fast.cometondemand.net/' + chat_appid + 'x_xchat.js';
        var chat_script = document.getElementsByTagName('script')[0];
        chat_script.parentNode.insertBefore(chat_js, chat_script);
      })();
    `
    document.body.appendChild(script2)

    return () => {
      script1.remove()
      script2.remove()
    }
  }, [])

  const getUserData = async () => {
    try {
      const user = await auth.findOneUser(userId)
      delete user?.data?.password
      return user?.data
    } catch (error) {
      console.error({ error })
    }
  }

  const getCompanyData = async () => {
    try {
      const companyData = await auth.findOneCompany({ id: userCompanyId || '' })
      return companyData?.data
    } catch (error) {
      console.error({ error })
    }
  }

  const handleUserImage = async (company: GetOneCompanyDTO, user: UserDataDTO) => {
    const path = company ? '/companies/company-image/get/' : '/users/profile-image/get/'
    const image = company ? company?.image : user?.image
    const url = await fetchSingleImage(image as string, path, auth)
    return url
  }

  useEffect(() => {
    const cleanup = async () => {
      const chatInstance = await loadChat()
      if (chatInstance) return chatInstance
    }

    cleanup()
  }, [loadChat])

  return <div id="atom-chat-container"></div>
}

export default AtomChat
