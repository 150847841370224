import '../../styles/style.css'

import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import Select, { MultiValue } from 'react-select'
import makeAnimated from 'react-select/animated'

import checked from '../../assets/images/checked.svg'
import { GetCustomProductFilter, Product, selectDTO } from '../../types/Product'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { useSearchParams } from 'react-router-dom'
import { CgLoadbar } from 'react-icons/cg'
import { BsPlusLg } from 'react-icons/bs'
import {
  getProductSizeRange,
  getSelectOrderLabel,
  getSelectTypeLabel
} from '../../providers/mapProviders'
import Item from '../../Components/Item'
import ScrollDetector from '../../Components/ScrollDetector'
import { Loading } from '../../Components/Loading'
import fetchImages from '../../utils/FetchImages'

const animatedComponents = makeAnimated()

export default function Announcements() {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const userPersonalSize = searchParams.get('size') || ''
  const customProductName = searchParams.get('name') || ''
  const customProductType = searchParams.get('type') || ''

  const auth = useContext(AuthContext)
  const wrapperRef = useRef(null)

  const [order, setOrder] = useState<string | undefined>('recent')
  const [closeTabs, setCloseTabs] = useState(true)
  const [productType, setProductType] = useState<string | undefined>(
    customProductType
  )
  const [sizes, setSizes] = useState<MultiValue<unknown> | undefined>()
  const [conditionNew, setConditionNew] = useState(true)
  const [conditionUsed, setConditionUsed] = useState(false)

  const [products, setProducts] = useState<Product[]>([])
  const [productModal, setProductModal] = useState(false)
  const [selectProduct, setSelectProduct] = useState<Product>()
  const [localLoading, setLocalLoading] = useState(false)
  const [globalFilteredSizeValues, setGlobalFilteredSizeValues] = useState<string[] | undefined>()

  const [productImages, setProductImages] = useState<any>({})

  let page = 0

  const getFilteredProducts = async (
    filteredSizeValues: string[] | undefined
  ) => {
    setGlobalFilteredSizeValues(filteredSizeValues)

    const searchParams: GetCustomProductFilter = {
      order,
      productType,
      sizes: userPersonalSize || filteredSizeValues,
      condition: conditionNew === true ? 'new' : 'used'
    }

    if (customProductName) searchParams.name = customProductName
    if (customProductType) searchParams.type = customProductType

    const products = await auth.getProductsByCustomFilter(searchParams)
    setProducts(products?.data?.results || [])
  }

  const addMoreResults = async () => {
    if (!products || localLoading) return
    setLocalLoading(true)

    try {
      page++

      const searchParams: GetCustomProductFilter = {
        order,
        productType,
        sizes: userPersonalSize || globalFilteredSizeValues,
        condition: conditionNew === true ? 'new' : 'used',
        page,
        limit: 10
      }

      if (customProductName) searchParams.name = customProductName
      if (customProductType) searchParams.type = customProductType

      const extraData = await auth.getProductsByCustomFilter(searchParams)

      const extraAnnouncements = extraData?.data?.results
      if (extraAnnouncements) {
        for (const extraDrop of extraAnnouncements) {
          const existingProduct = products.find((result) => result.id === extraDrop.id)
          if (!existingProduct) {
            setProducts((prevResults) => [...prevResults, extraDrop])
          }
        }
      }
    } catch (err) {
      console.error({ err })
    } finally {
      setLocalLoading(false)
    }
  }

  useEffect(() => {
    const sizeValues: selectDTO[] | undefined = sizes?.map(
      (size): selectDTO => size as selectDTO
    )
    const filteredSizeValues = sizeValues?.map(data => data?.value)

    getFilteredProducts(filteredSizeValues)
  }, [order, productType, sizes, conditionNew, conditionUsed])

  useEffect(() => {
    const fetchProductImagesForAll = async () => {
      for await (const product of products) {
        for await (const image of JSON.parse(product.images)) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }
    }

    fetchProductImagesForAll()
  }, [products])

  return (
    <>
      <ScrollDetector onEndOfPage={addMoreResults} timeout={200}>
        {productModal && (
          <Item
            productModal={productModal}
            setProductModal={setProductModal}
            product={selectProduct}
            wrapperRef={wrapperRef}
          />
        )}

        <div className="anor_fn_search_page">
          <div className={closeTabs ? 'anor_fn_tabs closed' : 'anor_fn_tabs'}>
            <div className="tab_bottom">
              <div className="anor_fn_filters">
                <div className="filter_header">
                  <span
                    className="custon_icon"
                    onClick={() => setCloseTabs(!closeTabs)}
                  >
                    {closeTabs ? <BsPlusLg size={15} /> : <CgLoadbar />}
                  </span>
                  <span className="text">Filtro</span>
                </div>

                <div className="filter_content">
                  <div className="filter_item filter_status">
                    <div className="filter_item__header">
                      <span className="text">Condição</span>
                      <span className="icon"></span>
                    </div>
                    <div className="filter_item__content">
                      <div className="ic_in">
                        <ul className="items">
                          <li className="half">
                            <div className="item">
                              <label className="checkbox">
                                Novo
                                <input
                                  type="checkbox"
                                  checked={conditionNew}
                                  onChange={e => {
                                    setConditionNew(e.target.checked)
                                    setConditionUsed(!e.target.checked)
                                  }}
                                />
                                <span className="checkmark">
                                  <img src={checked} alt="" className="fn__svg" />
                                </span>
                              </label>
                            </div>
                          </li>
                          <li className="half">
                            <div className="item">
                              <label className="checkbox">
                                Usado
                                <input
                                  type="checkbox"
                                  checked={conditionUsed}
                                  onChange={e => {
                                    setConditionUsed(e.target.checked)
                                    setConditionNew(!e.target.checked)
                                  }}
                                />
                                <span className="checkmark">
                                  <img src={checked} alt="" className="fn__svg" />
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {!customProductType && (
                    <div className="filter_item filter_price">
                      <div className="filter_item__header">
                        <span className="text">Tipo de Produto</span>
                        <span className="icon"></span>
                      </div>
                      <div className="filter_item__content">
                        <div className="ic_in">
                          <ul className="items">
                            <li className="field col4 start">
                              <div className="field_item dd_filter">
                                <Select
                                  className="custom_select"
                                  value={{
                                    value: productType,
                                    label: getSelectTypeLabel(productType)
                                  }}
                                  placeholder={'Escolha...'}
                                  options={[
                                    { value: 'sneakers', label: 'Tênis' },
                                    { value: 'clothing', label: 'Roupa' },
                                    { value: 'acessories', label: 'Acessórios' }
                                  ]}
                                  onChange={e => setProductType(e?.value)}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {!userPersonalSize && (
                    <div className="filter_item filter_price">
                      <div className="filter_item__header">
                        <span className="text">Tamanhos</span>
                        <span className="icon">
                          <img src="svg/down.svg" alt="" className="fn__svg" />
                        </span>
                      </div>
                      <div className="filter_item__content">
                        <div className="ic_in">
                          <ul className="items">
                            <li>
                              <Select
                                className="custom_select"
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                placeholder={'Escolha...'}
                                options={getProductSizeRange(productType)}
                                onChange={e => setSizes(e)}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="tab_content">
                <div className="tab_header">
                  <div className="anor_fn_searchpanel">
                    <div className="search_left">
                      <div className="filter_item dd_filter">
                        <div
                          className="input_wrapper"
                          style={{ maxWidth: '100%', minWidth: '330px' }}
                        >
                          <Select
                            className="custom_select"
                            value={{
                              value: order,
                              label: getSelectOrderLabel(order)
                            }}
                            placeholder={'Escolha...'}
                            defaultValue={{
                              value: 'recent',
                              label: 'Mais Recentes'
                            }}
                            options={[
                              { value: 'recent', label: 'Mais Recentes' },
                              {
                                value: 'lowestToBigger',
                                label: 'Preço: Menor para o Maior'
                              },
                              {
                                value: 'biggerToLowest',
                                label: 'Preço: Maior para o Menor'
                              }
                            ]}
                            onChange={e => setOrder(e?.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="search_right">
                      <p>{products?.length || 0} Itens</p>
                    </div>
                  </div>
                </div>

                <div className="tab_list">
                  <ul
                    className="anor_fn_list"
                    id="anor_fn_list"
                    data-cols="5"
                    data-gap="10"
                  >
                    {products.map((product, index) => (
                      <li key={index}>
                        <div className="fn__single_item">
                          <div className="img_holder example">
                            <a
                              className="full_link"
                              onClick={() => {
                                setProductModal(true)
                                setSelectProduct(product)
                              }}
                            ></a>
                            <img
                              src={productImages ? productImages[JSON.parse(product?.images)[0].split('.')[0]] : null}
                              style={{ width: '100%', height: '220px' }}
                              alt=""
                            />
                          </div>
                          <div
                            className="title_holder"
                            style={{ padding: '10px 5px' }}
                          >
                            <h3 className="fn_title" style={{ fontSize: '14px' }}>
                              <a>{product.name}</a>
                            </h3>
                            <p
                              style={{
                                fontSize: '12px',
                                display: 'block',
                                textAlign: 'left'
                              }}
                            >
                              R$ {product.price}
                            </p>
                            <p
                              key={index}
                              style={{
                                fontSize: '16px',
                                margin: '0',
                                padding: '0',
                                color: '#777',
                                lineHeight: '1.5'
                              }}
                            >
                              {JSON.parse(product?.sizes!).map(
                                (
                                  size:
                                    | string
                                    | number
                                    | boolean
                                    | ReactElement<
                                        any,
                                        string | JSXElementConstructor<any>
                                      >
                                    | ReactFragment
                                    | ReactPortal
                                    | null
                                    | undefined,
                                  index: Key | null | undefined
                                ) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: '11px',
                                      display: 'inline-block',
                                      borderRadius: '3px',
                                      color: '#FF5B36',
                                      padding: '0 6px',
                                      backgroundColor: '#EEEEEE',
                                      fontWeight: '600',
                                      marginRight: '3px'
                                    }}
                                  >
                                    {size}
                                  </span>
                                )
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                    {products?.length === 0 && (
                      <div className="fn__single_item">
                        <p style={{ textAlign: 'center' }}>
                          Não existem produtos disponíveis
                        </p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          {localLoading && (
            <Loading />
          )}
          </div>
        </div>
      </ScrollDetector>
    </>
  )
}
