import './style.css'

export default function NotFound() {
  return (
    <>
      <div className="anor_fn_add_item_page">
        <div className="container small">
          <div className="not-found">
            <h1>404</h1>
            <h2>Página não encontrada</h2>
            <p>Desculpe, não foi possível encontrar a página que você estava procurando.</p>
          </div>
        </div>
      </div>
    </>
  )
}
