import '../../styles/style.css'

import createStore from '../../assets/images/create-store.png'
import addProduct from '../../assets/images/add-product.png'
import analysis from '../../assets/images/analysis.png'
import approved from '../../assets/images/approved.png'
import desactivated from '../../assets/images/desactivated.png'

export default function About() {
  return (
    <>
      <div className="anor_fn_blog_single">
        <div className="container small">
          <div className="blog_single">
            <div className="blog_container">
              <h3 className="fn_title" style={{ textAlign: 'center' }}>
                Quem somos nós?
              </h3>

              <div className="blog_content" style={{ textAlign: 'center' }}>
                <p>
                  A Se Liga MRKT é um marketplace com foco em sneakers e apparel
                  que nasceu para ajudar revendedores a comercializerem seus
                  itens com mais liberdade! Nossa vontade de fazer isso nasceu
                  após notarmos uma demanda dos membros da nossa comunidade a Se
                  Liga No Drop, nossa missão é te ajudar a comprar e vender com
                  mais liberdade e segurança!
                </p>
              </div>

              <h3
                className="fn_title"
                style={{ textAlign: 'center', marginTop: '50px' }}
              >
                Como usar a plataforma?
              </h3>

              <div className="blog_content" style={{ textAlign: 'center' }}>
                <p>
                  Criamos a plataforma para ela ser o mais simples possível!
                  Abaixo listamos tudo que você precisa saber para criar sua
                  loja e subir seus anúncios na Se Liga MRKT
                </p>
              </div>

              <div
                className="blog_content"
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  position: 'relative',
                  left: '7%'
                }}
              >
                <div className="blog_content_left">
                  <h2 className="fn_title">Criando sua loja!</h2>
                  <p>
                    Para criar sua loja basta clicar no canto superior direito e
                    em seguida “Criar Loja”
                  </p>
                </div>
                <div className="blog_content_right">
                  <img src={createStore} alt="" />
                </div>
              </div>

              <div
                className="blog_content add_product"
                style={{
                  marginTop: '60px',
                  display: 'flex',
                  position: 'relative'
                }}
              >
                <div className="blog_secondary_content_left">
                  <img src={addProduct} alt="" />
                </div>
                <div className="blog_secondary_content_right">
                  <h2 className="fn_title">Comece a vender agora!</h2>
                  <p>
                    Para começar a vender basta clicar em “Vender Agora” e
                    preencher as informações dos eu anúncio e pronto!
                  </p>
                </div>
              </div>

              <h3
                className="fn_title"
                style={{ textAlign: 'center', marginTop: '50px' }}
              >
                Sistema de Aprovação
              </h3>

              <div className="blog_content" style={{ textAlign: 'center' }}>
                <p>
                  Após subir seu anúncio ele irá para a etapa de aprovação onde
                  nossa equipe irá verificar se tudo está de acordo com seu
                  anúncio.
                </p>

                <div className="status_images" style={{ marginBottom: '20px' }}>
                  <img src={analysis} alt="" style={{ width: '30%' }} />
                  <img src={desactivated} alt="" style={{ width: '30%' }} />
                  <img src={approved} alt="" style={{ width: '30%' }} />
                </div>

                <p>
                  Depois de aprovado seu anúncio ja estará no ar na plataforma!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
