import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Product from '../pages/Product'
import Profile from '../pages/Profile'
import Sales from '../pages/Sales'
import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'
import Company from '../pages/Company'
import Market from '../pages/Market'
import Announcements from '../pages/Announcements'
import About from '../pages/About'
import Admin from '../pages/Admin'
import Drops from '../pages/Drops'
import Drop from '../pages/Drop'
import Recover from '../pages/Recover'
import NotFound from '../pages/NotFound'

export default function Indexes() {
  const accountToken = localStorage.getItem('authentication')
  const deslogged = !accountToken

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/loja" element={!deslogged ? <Company /> : <SignIn />} />
      <Route path="/perfil" element={<Profile />} />
      <Route path="/product" element={<Product />} />
      <Route path="/meus-anuncios" element={<Sales />} />
      <Route path="/l/:marketName" element={<Market />} />
      <Route path="/anuncios" element={<Announcements />} />
      <Route path="/about" element={<About />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/drops" element={<Drops />} />
      <Route path="/drop/:id" element={<Drop />} />
      <Route path='/recover' element={<Recover />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
