import { Swiper } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Pagination, Navigation } from 'swiper'

export default function SwiperConfig({ children }: any) {
  return (
    <>
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        slidesPerGroup={3}
        loop={true}
        loopFillGroupWithBlank={true}
        modules={[Pagination, Navigation]}
        breakpoints={{
          280: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          360: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          375: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          390: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          520: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50
          }
        }}
      >
        {children}
      </Swiper>
    </>
  )
}
