import '../../styles/style.css'

import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { DropData } from '../../types/Drop'
import { useNavigate } from 'react-router-dom'
import ScrollDetector from '../../Components/ScrollDetector'
import { Loading } from '../../Components/Loading'
import { isEmpty } from 'lodash'
import fetchImages from '../../utils/FetchImages'

export default function Drops() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const [drops, setDrops] = useState<DropData[]>([])
  const [localLoading, setLocalLoading] = useState(false)
  const [page, setPage] = useState(1)

  const [dropImages, setDropImages] = useState<any>({})

  const getAllDrops = async () => {
    const dropsData = await auth.getAllDrops({ limit: 10 })
    setDrops(dropsData?.data?.results || [])
  }

  const addMoreResults = async () => {
    if (!drops || localLoading) return
    setLocalLoading(true)

    try {
      setPage((prevPage) => prevPage + 1)
      const extraData = await auth.getAllDrops({ limit: 10, page })
      const extraDrops = extraData?.data?.results

      if (extraDrops) {
        setDrops((prevResults) => {
          return [...prevResults, ...extraDrops]
        })
      }
    } catch (err) {
      console.error({ err })
    } finally {
      setLocalLoading(false)
    }
  }

  useEffect(() => {
    getAllDrops()
  }, [])

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const drop of drops) {
        await fetchImages(drop.image, '/drop/drop-image/get/', setDropImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [drops])

  return (
    <>
      <ScrollDetector onEndOfPage={addMoreResults} timeout={200}>
        <div className="anor_fn_discover">
          <div className="container">
            <div
              className="discover_page"
              style={drops.length === 0 ? { marginBottom: '17%' } : {}}
            >
              <div className="page_title_holder" style={{ zIndex: 0 }}>
                <h3 className="fn_title">Últimos Drops</h3>
                <p className="fn_desc">
                  Acompanhe os lançamentos de todas as Marcas
                </p>
              </div>

              <div className="anor_fn_items_list">
                <ul className="anor_fn_list" data-cols="5" data-gap="20">
                  {isEmpty(drops) && <></>}
                  {drops.map((drop, index) => (
                    <li key={index}>
                      <div className="fn__single_item">
                        <div className="img_holder">
                          <a
                            className="full_link"
                            onClick={() => {
                              navigate(`/drop/${drop?.id}`)
                            }}
                          ></a>
                          <img
                            src={dropImages
                              ? dropImages[drop?.image?.split('.')[0]]
                              : null}
                            alt=""
                          />
                        </div>
                        <div className="title_holder">
                          <h3
                            className="fn_title"
                            style={{
                              fontSize: '18px',
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }}
                          >
                            <a>{drop?.name}</a>
                          </h3>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="clearfix"></div>
              </div>
            </div>
            {localLoading && (
              <Loading />
            )}
          </div>
        </div>
      </ScrollDetector>
    </>
  )
}
