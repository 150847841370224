import React from 'react'
import { AuthContextType } from '../Contexts/Auth/AuthContext'

export default async function fetchImages(imageName: string, path: string, setImages: React.Dispatch<React.SetStateAction<object>>, auth: AuthContextType) {
  try {
    if (!imageName) return null

    const propertyName = imageName.split('.')[0]

    const url = await auth.genericGetImage(imageName, path)

    if (!url) return null

    setImages((prevImages: any) => ({ ...prevImages, [propertyName]: url }))
  } catch (error) {
    console.error(error)
  }
}

export async function fetchSingleImage(imageName: string, path: string, auth: AuthContextType, blob = false) {
  try {
    if (!imageName) return null

    const url = await auth.genericGetImage(imageName, path)

    if (!url) return null

    return url
  } catch (error) {
    console.error(error)
  }
}
