import React, { useEffect } from 'react'
import cancel from '../../../../assets/images/cancel.svg'
import alert from '../../../../assets/images/alert_circle.svg'

type ProductWarningModalDTO = {
  warningProductModal: boolean
  setWarningProductModal: React.Dispatch<React.SetStateAction<boolean>>
  wrapperRef: React.MutableRefObject<null>
  userId: string | undefined
  setProductModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Warning(props: ProductWarningModalDTO) {
  const { warningProductModal, setWarningProductModal, wrapperRef, userId, setProductModal } = props

  const handleSendMessage = () => {
    // eslint-disable-next-line no-useless-escape
    const script = `jqcc.cometchat.launch({uid: \'${userId}\'});`
    // eslint-disable-next-line no-eval
    eval(script)
    setWarningProductModal(false)
    setProductModal(false)
  }

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target))
          setWarningProductModal(false)
      }
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchstart', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  return (
    <>
      <div
        className="anor_fn_modal"
        style={
          warningProductModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                maxHeight: 'calc(100vh - 10px)',
                overflowY: 'auto',
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div
          className="warning_modal_body modal_in"
          ref={wrapperRef}
          style={
            warningProductModal
              ? {
                  opacity: 1,
                  visibility: 'visible',
                  zIndex: 9999,
                  maxHeight: 'calc(100vh - 1px)',
                  overflowY: 'auto',
                  transform: 'scale(1) translateY(0px)',
                  borderRadius: '30px'
                }
              : { transform: 'scale(1) translateY(0px)' }
          }
        >
          <div className="modal_closer" onClick={() => setWarningProductModal(false)}>
            <img src={cancel} alt="" className="fn__svg" />
          </div>

          <div className="anor_fn_product_page">
            <div className="anor_fn_product">
              <div className="container small" style={{ padding: '0 40px', maxWidth: '100%' }}>
                <div className="anor_fn_product_info" style={{ paddingTop: '20px', display: 'block' }}>
                  <div className='announcement_warning_header' style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', marginTop: '8%' }}>
                    <img src={alert} width={70} alt="" />
                    <h2>AVISO</h2>
                  </div>
                  <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem' }}>
                    Informamos que nossa empresa não é responsável por quaisquer itens vendidos dentro da plataforma. Esses itens estão sendo vendidos por indivíduos e, como tal, não podemos garantir sua autenticidade ou condição. Recomendamos que você pesquise minuciosamente todos os itens que está interessado em comprar, priorize vendedores verificados. Agradecemos pela sua compreensão.
                  </p>
                  <div
                    className="product_details"
                    style={{
                      justifyContent: 'center',
                      padding: '40px 50px 70px 50px'
                    }}
                  >
                    <a
                      className="product_details_button buy"
                      onClick={() => handleSendMessage()}
                      style={{
                        margin: '0',
                        lineHeight: '3rem',
                        padding: '0 8%'
                      }}
                    >
                      Enviar Mensagem
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
