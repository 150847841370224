import useMediaQuery from '../../../utils/MediaQuery'

export const setLabelStyle = (label: string) => {
  let labelStyles = {}

  switch (true) {
    case useMediaQuery('(min-width: 390px)'):
      labelStyles = {
        stores: {
          left: '169px',
          width: '146.203px',
          top: '55px',
          bottom: 'auto'
        },
        announcements: {
          left: '34px',
          width: '148px',
          top: '55px',
          bottom: 'auto'
        },
        drops: {
          left: '284px',
          width: '148px',
          top: '55px',
          bottom: 'auto'
        }
      }
      break
    case useMediaQuery('(min-width: 1400px)'):
      labelStyles = {
        stores: {
          left: '119px',
          width: '146.203px',
          top: '55px',
          bottom: 'auto'
        },
        announcements: {
          left: '0px',
          width: '148px',
          top: '55px',
          bottom: 'auto'
        },
        drops: {
          left: '216px',
          width: '148px',
          top: '55px',
          bottom: 'auto'
        }
      }
      break
    default:
      labelStyles = {
        stores: {
          left: '168px',
          width: '146.203px',
          top: '55px',
          bottom: 'auto'
        },
        announcements: {
          left: '35px',
          width: '148px',
          top: '55px',
          bottom: 'auto'
        },
        drops: {
          left: '285px',
          width: '148px',
          top: '55px',
          bottom: 'auto'
        }
      }
      break
  }
  return labelStyles[label as keyof typeof labelStyles]
}
