import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SwiperSlide } from 'swiper/react'
import SwiperConfig from '../../../../Components/SwiperConfig'
import { AuthContext } from '../../../../Contexts/Auth/AuthContext'
import { DropData } from '../../../../types/Drop'
import fetchImages from '../../../../utils/FetchImages'

export default function Drops() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const [drops, setDrops] = useState<DropData[]>([])
  const [dropImages, setDropImages] = useState<any>({})

  const getAllDrops = async () => {
    const dropsData = await auth.getAllDrops({ limit: 9 })
    setDrops(dropsData?.data?.results)
  }

  useEffect(() => {
    getAllDrops()
  }, [])

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const drop of drops) {
        await fetchImages(drop.image, '/drop/drop-image/get/', setDropImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [drops])

  return (
    <>
      {drops && (
        <section id="auction">
          <div className="container">
            <div className="anor_fn_auction_slider">
              <div className="anor_fn_slider_title">
                <h3 className="fn_title">Últimos Drops</h3>
                <Link className="view_all" to={'/drops'}>
                  <span></span>Ver Todos
                </Link>
              </div>

              <div className="anor_fn_slider_holder">
                <div className="flickity_slider_wrap">
                  <div className="owl-carousel owl-theme owl-loaded owl-drag">
                    <div className="owl-stage-outer">
                      <div className="owl-stage">
                        {!drops[0] && (
                          <p style={{ textAlign: 'center' }}>
                            Não existem drops disponíveis no momento
                          </p>
                        )}
                        {drops.length > 1 && drops.length > 0
                          ? (
                          <SwiperConfig>
                            {drops.map((drop, index) => (
                              <SwiperSlide
                                className="fn__single_item item"
                                key={index}
                                onClick={() => navigate(`/drop/${drop?.id}`)}
                                style={{ cursor: 'pointer' }}
                              >
                                <div className="img_holder">
                                  <img
                                    src={dropImages
                                      ? dropImages[drop?.image?.split('.')[0]]
                                      : null}
                                    width={500}
                                    onClick={() => navigate(`/drop/${drops[0]?.id}`)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                                <div className="drop-title">
                                  <h5
                                    className="fn_title"
                                    style={{ marginBottom: '5px' }}
                                  >
                                    <a>{drop?.name.length > 27 ? `${drop?.name.substring(0, 27)}...` : drop?.name}</a>
                                  </h5>
                                </div>
                              </SwiperSlide>
                            ))}
                          </SwiperConfig>
                            )
                          : (
                              drops[0] && (
                            <>
                              <div className="img_holder">
                                <img
                                  src={dropImages
                                    ? dropImages[drops[0]?.image?.split('.')[0]]
                                    : null}
                                  width={315}
                                  style={{ borderRadius: '25px', cursor: 'pointer' }}
                                  onClick={() => navigate(`/drop/${drops[0]?.id}`)}
                                />
                              </div>
                              <div className="drop-title">
                                <h5
                                  className="fn_title"
                                  style={{ marginBottom: '5px' }}
                                >
                                  <a>{drops[0]?.name.length > 27 ? `${drops[0]?.name.substring(0, 27)}...` : drops[0]?.name}</a>
                                </h5>
                              </div>
                            </>
                              )
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
