import React, { useContext, useEffect, useState } from 'react'
import '../../styles/style.css'

import cancel from '../../assets/images/cancel.svg'
import { Product } from '../../types/Product'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'

import { Navigation } from 'swiper'
import { getAnnouncementBoxState } from '../../providers/mapProviders'
import { useNavigate } from 'react-router-dom'
import Warning from './components/Warning'
// import Popover from './components/Popover'
import fetchImages from '../../utils/FetchImages'
import { AuthContext } from '../../Contexts/Auth/AuthContext'

type ProductModalDTO = {
  productModal: boolean
  setProductModal: React.Dispatch<React.SetStateAction<boolean>>
  product: Product | undefined
  wrapperRef: React.MutableRefObject<null>
}

export default function Item(props: ProductModalDTO) {
  const {
    productModal,
    setProductModal,
    product,
    wrapperRef
  } = props

  const auth = useContext(AuthContext)

  const images: Array<string> = JSON.parse(product?.images || '')

  const [warningProductModal, setWarningProductModal] = useState(false)
  // const [showPopover, setShowPopover] = useState(false)
  // const popoverRef = useRef<HTMLDivElement>(null)
  const [productImages, setProductImages] = useState<any>({})

  const navigate = useNavigate()

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target))
          setProductModal(false)
      }

      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('touchstart', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchstart', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  if (warningProductModal)
    return <Warning
      warningProductModal={warningProductModal}
      setWarningProductModal={setWarningProductModal}
      wrapperRef={wrapperRef}
      userId={product?.user.id}
      setProductModal={setProductModal}
    />

  useEffect(() => {
    if (images) {
      const fetchProductImagesForAll = async () => {
        for await (const image of images) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }

      fetchProductImagesForAll()
    }
  }, [product])

  return (
    <>
      <div
        className="anor_fn_modal"
        style={
          productModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                maxHeight: 'calc(100vh - 10px)',
                overflowY: 'auto',
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div
          className="modal_in product_modal_in"
          ref={wrapperRef}
          style={
            productModal
              ? {
                  opacity: 1,
                  visibility: 'visible',
                  zIndex: 9999,
                  maxHeight: 'calc(100vh - 1px)',
                  overflowY: 'auto',
                  transform: 'scale(1) translateY(0px)',
                  borderRadius: '20px'
                }
              : { transform: 'scale(1) translateY(0px)' }
          }
        >
          <div className="modal_closer" onClick={() => setProductModal(false)}>
            <img src={cancel} alt="" className="fn__svg"/>
          </div>
          <div className="anor_fn_product_page">
            <div className="anor_fn_product">
              <div className="container small product_container">
                <div className="anor_fn_product_info">
                  <div className="product_left">
                    <div className="product_img announcement_img" style={{ width: '90%' }}>
                      <div className="img_holder">
                        <Swiper
                          slidesPerView={1}
                          spaceBetween={30}
                          slidesPerGroup={1}
                          loop={true}
                          loopFillGroupWithBlank={true}
                          navigation={true}
                          modules={[Navigation]}
                          className="mySwiper"
                        >
                          {images.map((image, index) => (
                            <SwiperSlide key={index}>
                              <img
                                src={productImages ? productImages[image.split('.')[0]] : null}
                                alt=""
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                  <div className="product_right" style={{ paddingLeft: '0' }}>
                    <div
                      className="price_box"
                      style={{
                        height: '89%'
                      }}
                    >
                      <div
                        className="product_header"
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '4%'
                        }}
                      >
                        <h3
                          className="fn_title"
                          style={{
                            fontSize: '2.2rem',
                            marginBottom: '0',
                            marginRight: '1%'
                          }}
                        >
                          {product?.name}
                        </h3>
                        {/* Exibe os sizes do lado caso o nome do produto seja menor que 23 caractéres */}
                        {JSON.stringify(product?.name)?.length < 23 && JSON.parse(product?.sizes || '').map(
                          (size: number, index: number) => (
                            <span
                              key={index}
                              style={{
                                fontSize: '1.1rem',
                                background: 'linear-gradient(91.3deg, #FF5E02 2.26%, #F9A170 99.91%)',
                                padding: '2px',
                                fontWeight: 'bold',
                                borderRadius: '20%',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                                marginRight: '1%'
                              }}
                            >
                              {size}
                            </span>
                          )
                        )}
                      </div>

                      {/* Caso seja maior que 23 caractéres exibe abaixo do nome */}
                      {JSON.stringify(product?.name)?.length > 23 && (
                        <div className='custom_sizes_container'>
                          {JSON.parse(product?.sizes || '').map(
                            (size: number, index: number) => (
                            <span
                              key={index}
                              style={{
                                fontSize: '1.1rem',
                                background: 'linear-gradient(91.3deg, #FF5E02 2.26%, #F9A170 99.91%)',
                                padding: '2px',
                                fontWeight: 'bold',
                                borderRadius: '20%',
                                paddingRight: '4px',
                                paddingLeft: '4px',
                                marginRight: '1%'
                              }}
                            >
                              {size}
                            </span>
                            )
                          )}
                        </div>
                      )}

                      <div
                        className="product_price"
                        style={{
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '1.3%'
                        }}
                      >
                        <h2 style={{ fontSize: '2.8rem' }}>
                          R$ {product?.price}
                        </h2>
                      </div>

                      <div className="product_data">
                        <div>
                          <h6>
                            CONDIÇÃO:{' '}
                            <span style={{ color: '#949494' }}>
                              {!product?.conditionLevel
                                ? '10'
                                : product?.conditionLevel}
                              /10
                            </span>
                          </h6>
                          <h6>
                            CONDIÇÃO:{' '}
                            <span style={{ color: '#949494' }}>
                              {product?.condition === 'used' ? 'USADO' : 'NOVO'}
                            </span>
                          </h6>
                          <h6>
                            PRODUTO:{' '}
                            <span style={{ color: '#949494' }}>
                              {product?.inHands ? 'EM MÃOS' : 'ENVIO'}
                            </span>
                          </h6>
                          {product?.box && (
                            <h6>
                              CAIXA:{' '}
                              <span style={{ color: '#949494' }}>
                                {product?.box === 'no-data' ? 'NÃO POSSUÍ' : getAnnouncementBoxState(product?.box)}
                              </span>
                            </h6>
                          )}
                        </div>
                      </div>

                      <div className="product_description" style={{ textAlign: 'center' }}>
                        <h6 style={{ marginBottom: '-0.3%' }}>
                          DESCRIÇÃO:
                        </h6>
                        <p style={{
                          padding: '5%',
                          paddingTop: '0',
                          fontSize: '1rem'
                        }}>
                          {product?.description
                            ? product?.description
                            : 'Sem descrição'}
                        </p>
                      </div>

                      <div
                        className="product_store"
                        style={{
                          justifyContent: 'center',
                          textAlign: 'center'
                        }}
                      >
                        <h6 style={{ marginBottom: '1%' }} onClick={() => navigate(`/l/${product?.createdBy}`)}>
                          {product?.createdBy}
                        </h6>
                      </div>
                      <div
                        className="product_details"
                        style={{
                          justifyContent: 'center',
                          padding: '0 50px 30px 50px'
                        }}
                      >
                        <a
                          className="product_details_button"
                          onClick={() => setWarningProductModal(true)}
                          style={{
                            margin: '0',
                            lineHeight: '3rem',
                            padding: '0 8%',
                            marginRight: '2%',
                            marginBottom: '3%'
                          }}
                        >
                          Enviar Mensagem
                        </a>

                        <a
                          className="product_details_button buy"
                          style={{
                            margin: '0',
                            lineHeight: '3rem',
                            padding: '0 8%',
                            marginBottom: '3%',
                            cursor: 'default',
                            background: '#bababa'
                          }}
                          // onMouseEnter={() => setShowPopover(true)}
                          // onTouchStart={() => setShowPopover(true)}
                          // onMouseLeave={() => setShowPopover(false)}
                          // onTouchCancel={() => setShowPopover(false)}
                        >
                          Comprar
                        </a>
                        {/* {showPopover && <Popover showPopover={showPopover} popoverRef={popoverRef} />} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
