import { SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'

import '../../../../styles/style.css'

import SwiperConfig from '../../../../Components/SwiperConfig'

import acessories from '../../../../assets/images/Acessórios.png'
import dunk from '../../../../assets/images/Dunk.png'
import jordan from '../../../../assets/images/Jordan.png'
import clothes from '../../../../assets/images/Roupas.png'
import sneakers from '../../../../assets/images/Sneakers.png'
import yeezy from '../../../../assets/images/Yeezy.png'

export default function QuickSearch() {
  const navigate = useNavigate()

  return (
    <>
      <section id="category">
        <div className="container">
          <div className="anor_fn_auction_slider">
            <div className="anor_fn_slider_title">
              <h3 className="fn_title">Busca Rápida</h3>
            </div>
            <div className="anor_fn_slider_holder">
              <div className="flickity_slider_wrap">
                <div className="owl-carousel owl-theme owl-loaded owl-drag">
                  <div className="owl-stage-outer">
                    <div className="owl-stage">
                      <SwiperConfig>
                        <SwiperSlide
                          className="fn__category_item item"
                          onClick={() =>
                            navigate('/anuncios?type=sneakers')
                          }
                        >
                          <a className="full_link"></a>
                          <img src={sneakers} width={500} />
                          <div className="title_holder">
                            <h3 className="fn_title" id="titulo-categoria">
                              <a>Sneakers</a>
                            </h3>
                            <p id="desc-categoria">Ver Todos</p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide
                          className="fn__category_item item"
                          onClick={() =>
                            navigate('/anuncios?type=clothing')
                          }
                        >
                          <a className="full_link"></a>
                          <img src={clothes} width={500} />
                          <div className="title_holder">
                            <h3 className="fn_title" id="titulo-categoria">
                              <a>Roupas</a>
                            </h3>
                            <p id="desc-categoria">Ver Todos</p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide
                          className="fn__category_item item"
                          onClick={() =>
                            navigate('/anuncios?type=clothing')
                          }
                        >
                          <a className="full_link"></a>
                          <img src={acessories} width={500} />
                          <div className="title_holder">
                            <h3 className="fn_title" id="titulo-categoria">
                              <a>Acessórios</a>
                            </h3>
                            <p id="desc-categoria">Ver Todos</p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide
                          className="fn__category_item item"
                          onClick={() => navigate('/anuncios?name=yeezy')}
                        >
                          <a className="full_link"></a>
                          <img src={yeezy} width={500} />
                          <div className="title_holder">
                            <h3 className="fn_title" id="titulo-categoria">
                              <a>Yeezy</a>
                            </h3>
                            <p id="desc-categoria">Ver Todos</p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide
                          className="fn__category_item item"
                          onClick={() => navigate('/anuncios?name=jordan')}
                        >
                          <a className="full_link"></a>
                          <img src={jordan} width={500} />
                          <div className="title_holder">
                            <h3 className="fn_title" id="titulo-categoria">
                              <a>Jordan</a>
                            </h3>
                            <p id="desc-categoria">Ver Todos</p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide
                          className="fn__category_item item"
                          onClick={() => navigate('/anuncios?name=dunk')}
                        >
                          <a className="full_link"></a>
                          <img src={dunk} width={500} />
                          <div className="title_holder">
                            <h3 className="fn_title" id="titulo-categoria">
                              <a>Dunk</a>
                            </h3>
                            <p id="desc-categoria">Ver Todos</p>
                          </div>
                        </SwiperSlide>
                      </SwiperConfig>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
