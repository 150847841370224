import React from 'react'
import './style.scss'

export default function Switch(props: {
  category: boolean
  setCategory: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { category, setCategory } = props

  return (
    <>
      <div className="switch-button">
        <input
          className="switch-button-checkbox"
          type="checkbox"
          onChange={() => setCategory(!category)}
        ></input>
        <label className="switch-button-label">
          <span className="switch-button-label-span">Novos</span>
        </label>
      </div>
    </>
  )
}
