import React, { ReactNode, useEffect, useRef } from 'react'

interface ScrollDetectorProps {
  onEndOfPage: () => void
  children: ReactNode
  timeout: number
}

const ScrollDetector: React.FC<ScrollDetectorProps> = ({ onEndOfPage, children, timeout }) => {
  const componentRef = useRef<HTMLDivElement>(null)
  let timeoutId: number | null = null

  useEffect(() => {
    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = window.setTimeout(async () => {
        if (
          componentRef.current &&
          componentRef.current.getBoundingClientRect().bottom <= window.innerHeight
        ) {
          await onEndOfPage()
        }
      }, timeout)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [onEndOfPage])

  return (
    <div ref={componentRef}>
      {children}
    </div>
  )
}

export default ScrollDetector
