import { selectDTO } from '../types/Product'

export const shoeSizes: selectDTO[] = [
  { value: '33', label: '33' },
  { value: '34', label: '34' },
  { value: '35', label: '35' },
  { value: '36', label: '36' },
  { value: '37', label: '37' },
  { value: '38', label: '38' },
  { value: '39', label: '39' },
  { value: '40', label: '40' },
  { value: '41', label: '41' },
  { value: '42', label: '42' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '47', label: '47' }
]

export const clothingSizes: selectDTO[] = [
  { value: 'Único', label: 'Único' },
  { value: 'P', label: 'P' },
  { value: 'M', label: 'M' },
  { value: 'G', label: 'G' },
  { value: 'GG', label: 'GG' },
  { value: 'GGG', label: 'GGG' },
  { value: 'GGGG', label: 'GGGG' },
  { value: 'GGGGG', label: 'GGGGG' }
]
