/* eslint-disable no-unused-vars */
import { Product } from '../../../types/Product'

export function filterSizeList(products: Product[]): Product[] | [] {
  if (!products) return []

  const list = splitProductsBySizes(products)
  const newList: Product[] = []

  list.forEach(productOnList => {
    const productAlreadyStored = alreadyStored(newList, productOnList.sizes)
    if (!productAlreadyStored) return newList.push(productOnList)

    const onListHasSmallerPrice = hasSmallerPrice(productOnList.price, productAlreadyStored.price)
    const productAlreadyStoredIndex = newList.findIndex(element => element === productAlreadyStored)
    if (onListHasSmallerPrice) newList[productAlreadyStoredIndex] = productOnList
  })

  const sortedBySizeNumberNewList = sortProductsBySizeNumber(newList)
  return sortedBySizeNumberNewList
}

function splitProductsBySizes(products: Product[]) {
  const productList: Product[] = []

  products.forEach(product => {
    const sizesList = JSON.parse(product.sizes)

    sizesList.forEach((size: string) => {
      productList.push({
        ...product,
        sizes: size
      })
    })
  })

  return productList
}

function alreadyStored(newList: Product[], productSize: string) {
  return newList.find(p => p.sizes === productSize)
}

function hasSmallerPrice(sizeOnList: string, sizeAlreadyStored: string) {
  const parsedSizeOnList = parseInt(sizeOnList.replaceAll(',', '').replaceAll('.', ''))
  const parsedSizeAlreadyStored = parseInt(sizeAlreadyStored.replaceAll(',', '').replaceAll('.', ''))
  return parsedSizeOnList < parsedSizeAlreadyStored
}

function sortProductsBySizeNumber(productList: Product[]) {
  return productList.sort((a, b) => {
    if (a.sizes < b.sizes) return -1
    return 1
  })
}
