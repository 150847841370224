import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useContext,
  useEffect,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { GetUserProductsDTO } from '../../types/Product'
import { GiConfirmed } from 'react-icons/gi'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import cancel from '../../assets/images/cancel.svg'

import '../../styles/style.css'
import { toast } from 'react-toastify'
import fetchImages from '../../utils/FetchImages'

export default function Sales() {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const companyName = localStorage.getItem('companyName') || ''
  const userId = localStorage.getItem('user') || ''

  const [editModal, setEditModal] = useState('')
  const [desactivateModal, setDesactivateModal] = useState('')
  const [category, setCategory] = useState<string | undefined>('available')
  const [products, setProducts] = useState<GetUserProductsDTO[]>([])

  const [productImages, setProductImages] = useState<any>({})

  const getUserProducts = async () => {
    try {
      const products = await auth.getAllUserProducts(companyName)
      setProducts(products?.data)
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  const handleDesactivateSale = async (productId: string) => {
    try {
      const updateProduct = await auth.updateUserProductData({
        id: productId,
        state: 'desactivated'
      })
      setDesactivateModal('')

      if (updateProduct?.data?.affected > 0)
        return toast.success('Anúncio destivado')
      return toast.error('Erro ao desativar anúncio')
    } catch (error) {
      console.error(error)
      return toast.error('Erro ao desativar anúncio')
    }
  }

  useEffect(() => {
    getUserProducts()
  }, [desactivateModal])

  useEffect(() => {
    if (!userId) return navigate('/', { replace: true })
  }, [])

  useEffect(() => {
    const fetchProductImagesForAll = async () => {
      for await (const product of products) {
        for await (const image of JSON.parse(product.images)) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }
    }

    fetchProductImagesForAll()
  }, [products])

  return (
    <>
      <div
        className="anor_fn_modal share_box"
        style={
          desactivateModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in">
          <div className="modal_closer">
            <img
              src={cancel}
              alt=""
              className="fn__svg"
              onClick={() => setDesactivateModal('')}
            />
          </div>
          {/* <div className="modal_title">Share</div> */}
          <div className="modal_content">
            <div className="share_title">Desativar anúncio?</div>
            <div className="share_list">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      handleDesactivateSale(desactivateModal)
                      setEditModal('')
                    }}
                    target="_blank"
                  >
                    <GiConfirmed />
                  </a>
                </li>
                <li>
                  <a onClick={() => setDesactivateModal('')} target="_blank">
                    <IoMdCloseCircleOutline />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="anor_fn_ranking_page" style={{ marginBottom: '20%' }}>
        <div className="container small">
          <div className="ranking_page">
            <div className="page_title_holder">
              <h3 className="fn_title">Meus Anúncios</h3>
              <p className="fn_desc">
                Utilize o filtro para verificar todos os anúncios.
                <br />
                Tempo médio para aprovação de anúncio: 2 horas
              </p>
              <div className="filters">
                <div className="dd_filter">
                  <div className="input_wrapper">
                    <select
                      name="gender"
                      className="filter_list"
                      onChange={e => setCategory(e.target.value)}
                    >
                      <option defaultValue={'available'} value={'available'}>
                        Ativo
                      </option>
                      <option value={'analysis'}>Em Análise</option>
                      <option value={'desactivated'}>Desativado</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* Collection List */}
            <div className="anor_fn_items_list" id="disponiveis">
              <ul className="anor_fn_list" data-cols="5" data-gap="20">
                {products?.map(
                  (product, index) =>
                    product.state === category && (
                      <li key={index}>
                        <div className="fn__single_item">
                          <div className="img_holder">
                            <a className="full_link"></a>
                            <img
                              src={productImages ? productImages[JSON.parse(product?.images)[0].split('.')[0]] : null}
                              style={{
                                width: '100%',
                                height: '220px',
                                objectFit: 'cover'
                              }}
                              alt=""
                            />
                            <div className="action_box">
                              {product.state !== 'desactivated' && (
                                <a
                                  className="action_btn"
                                  onClick={() =>
                                    setEditModal(
                                      product.id === editModal ? '' : product.id
                                    )
                                  }
                                >
                                  <span
                                    style={
                                      editModal === product.id
                                        ? {
                                            transform: 'rotate(90deg)',
                                            transition:
                                              'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                          }
                                        : {
                                            transition:
                                              'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                          }
                                    }
                                  ></span>
                                </a>
                              )}
                              <div
                                className="action_popup"
                                style={
                                  editModal === product.id
                                    ? {
                                        opacity: 1,
                                        visibility: 'visible',
                                        transform: `translate(${0}px, ${-5}px)`
                                      }
                                    : {}
                                }
                              >
                                <ul>
                                  <li>
                                    <a
                                      className="anor_fn_place_item"
                                      onClick={() => {
                                        navigate(
                                          `/product?productId=${product.id}`
                                        )
                                      }}
                                    >
                                      Editar anúncio
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="anor_fn_share_item"
                                      onClick={() =>
                                        setDesactivateModal(product.id)
                                      }
                                    >
                                      Desativar
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div
                            className="title_holder"
                            style={{ padding: '10px 5px' }}
                          >
                            <h3
                              className="fn_title"
                              style={{ fontSize: '14px' }}
                            >
                              <a>{product?.name}</a>
                            </h3>
                            <p
                              style={{
                                fontSize: '12px',
                                display: 'block',
                                textAlign: 'left'
                              }}
                            >
                              R${product?.price}
                            </p>
                            <p
                              style={{
                                display: 'block',
                                textAlign: 'right',
                                marginTop: '-23px'
                              }}
                            >
                              {JSON.parse(product.sizes!).map(
                                (
                                  size:
                                    | string
                                    | number
                                    | boolean
                                    | ReactElement<
                                        any,
                                        string | JSXElementConstructor<any>
                                      >
                                    | ReactFragment
                                    | ReactPortal
                                    | null
                                    | undefined,
                                  index: Key | null | undefined
                                ) => (
                                  <span
                                    key={index}
                                    style={{ fontSize: '11px' }}
                                  >
                                    {size}
                                  </span>
                                )
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
