import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import moment from 'moment'
// @ts-ignore
import MultiImageInput from 'react-multiple-image-input'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import '../../styles/style.css'
import { b64toBlob, filterBase64ImageData } from '../../providers/base64ToBlob'
import axios from 'axios'
import { getFileExtension } from '../../utils/FileExtension'
import { fetchSingleImage } from '../../utils/FetchImages'

export default function Profile() {
  const navigate = useNavigate()

  const userId = localStorage.getItem('user') || ''

  const auth = useContext(AuthContext)

  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [size, setSize] = useState('')
  const [images, setImages] = useState([])

  const getUserData = async () => {
    const userData = await auth.findOneUser(userId)

    setName(userData?.data?.name)
    setCpf(userData?.data?.cpf)
    setEmail(userData?.data?.email)
    setBirthDate(moment(userData?.data?.birthDate).format('YYYY-MM-DD'))
    setCellphone(userData?.data?.cellphone)
    setSize(userData?.data?.size)

    const url = await fetchSingleImage(userData?.data?.image, '/users/profile-image/get/', auth)
    handleImageMetadata(url as string)
  }

  const handleUpdateUserData = async () => {
    if (birthDate.includes('_'))
      return toast.error('Data de nascimento inválida')

    const parsedBirthDate = new Date(birthDate)

    let imagePath = ''

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(images)) {
      const imageData = filterBase64ImageData(value)

      const blob = b64toBlob(
        imageData?.imageMetadata,
        imageData?.imageType
      )
      const file = new File([blob], imageData?.imageType.split('/')[1] || '')
      const uploadResponse = await auth.updateUserImage(file)
      imagePath = uploadResponse?.data?.imagePath || ''
    }

    const response = await auth.updateUser(userId, {
      name,
      cpf,
      email,
      birthDate: parsedBirthDate,
      cellphone,
      size: parseInt(size),
      image: imagePath
    })

    if (response) {
      return toast.success('Dados atualizados')
    }

    return toast.error('Erro ao atualizar dados. Tente novamente mais tarde')
  }

  const handleImageMetadata = async (imageUrl: string) => {
    const fileExtension = getFileExtension(imageUrl)

    const imageData = await axios.get(imageUrl, { responseType: 'blob' })
    const reader = new FileReader()
    reader.readAsDataURL(imageData.data)
    reader.onloadend = () => {
      const base64String = reader.result?.toString().split(',')[1]
      setImages({ 0: `data:image/${fileExtension};base64,${base64String}` } as any)
    }
  }

  useEffect(() => {
    if (!userId) return navigate('/', { replace: true })
    getUserData()
  }, [userId])

  return (
    <>
      <div className="anor_fn_add_item_page">
        <div className="container small">
          <div className="anor_fn_add_item">
            <div className="field_part">
              <div className="top_title">
                <h3 className="fn_title">Dados Pessoais</h3>
              </div>
              <div className="fields_content">
                <form
                  action="sql/editarperfil.php"
                  id="editarperfil"
                  method="post"
                  encType="multipart/form-data"
                >
                  <ul className="fields">
                    <li className="field col8">
                      <div className="field_item">
                        <label className="label">Nome Completo</label>
                        <input
                          style={{ color: 'gray' }}
                          id="item_title"
                          type="text"
                          readOnly
                          name="name"
                          value={name}
                        />
                      </div>
                    </li>
                    <li className="field col4">
                      <div className="field_item">
                        <label className="label">CPF</label>
                        <input
                          style={{ color: 'gray' }}
                          type="text"
                          id="item_price"
                          readOnly
                          name="cpf"
                          value={cpf}
                        />
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item">
                        <label className="label">E-mail</label>
                        <input
                          style={{ color: 'gray' }}
                          id="item_title"
                          type="text"
                          readOnly
                          name="email"
                          value={email}
                        />
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item dd_filter">
                        <label className="label">Gênero</label>
                        <div className="input_wrapper">
                          <select
                            defaultValue={'Gênero'}
                            name="gender"
                            className="filter_list"
                            onChange={e => setGender(e.target.value)}
                            value={gender}
                          >
                            <option value={'Masculino'}>Masculino</option>
                            <option value={'Feminino'}>Feminino</option>
                            <option value={'Outro'}>Outro</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item">
                        <label className="label">Data de Nascimento</label>
                        <input
                          type="date"
                          name="birthDate"
                          id="birthDate"
                          placeholder=""
                          onChange={e => setBirthDate(e.target.value)}
                          value={birthDate}
                        />
                      </div>
                    </li>
                    <li className="field col4 start">
                      <div className="field_item">
                        <label className="label">Celular</label>
                        <InputMask
                          id="celular"
                          name="cellphone"
                          type="text"
                          onChange={e => setCellphone(e.target.value)}
                          value={cellphone}
                          mask="(99) 99999-9999"
                        />
                      </div>
                    </li>

                    <li className="field col4">
                      <div className="field_item dd_filter">
                        <label className="label">
                          Qual tamanho você calça?
                        </label>
                        <div className="input_wrapper">
                          <select
                            name="size"
                            className="filter_list"
                            onChange={e => setSize(e.target.value)}
                            value={size}
                          >
                            <option selected disabled hidden>
                              Tamanho
                            </option>
                            <option value={'33'}>33</option>
                            <option value={'34'}>34</option>
                            <option value={'35'}>35</option>
                            <option value={'36'}>36</option>
                            <option value={'37'}>37</option>
                            <option value={'38'}>38</option>
                            <option value={'39'}>39</option>
                            <option value={'40'}>40</option>
                            <option value={'41'}>41</option>
                            <option value={'42'}>42</option>
                            <option value={'43'}>43</option>
                            <option value={'44'}>44</option>
                            <option value={'45'}>45</option>
                            <option value={'46'}>46</option>
                            <option value={'47'}>47</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li className="field col5 start">
                      <div className="field_item" style={{ maxWidth: '215px' }}>
                        <label className="label">Foto do Perfil</label>
                        <MultiImageInput
                          max={1}
                          width={450}
                          height={450}
                          theme="light"
                          images={images}
                          setImages={setImages}
                          cropConfig={{
                            crop: {
                              unit: '%',
                              aspect: 1 / 1,
                              width: '100'
                            },
                            ruleOfThirds: true
                          }}
                        />
                      </div>
                    </li>

                    <li className="field">
                      <p></p>
                      <div className="field_item">
                        <div className="links">
                          <a
                            onClick={() => handleUpdateUserData()}
                            className="anor_fn_button small add"
                          >
                            Salvar Alterações
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
