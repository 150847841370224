import '../../../../styles/style.css'
import './style.css'

import discoverImg from '../../../../assets/images/discoverImg.png'
import discoverBg from '../../../../assets/images/discoverBg.svg'
import SearchBar from '../../../../Components/SearchBar'

export default function Discover() {
  return (
    <>
      <section id="discover">
        <div className="discover-container">
          <div className="anor_fn_discover">
            {/* Left part */}
            <div className="discover_left">
              <div className="left_in">
                <div className="disc_desc">
                  <h3>Compre e venda Sneakers com liberdade</h3>
                  <p>
                    Comercialize sneakers, roupas, acessórios e mais com total
                    liberdade através da nossa plataforma, a Se Liga Market!
                  </p>
                </div>
                <div className="disc_button">
                  <SearchBar />
                </div>
              </div>
            </div>

            {/* Right part */}
            <div className="discover_right">
              <div className="discover-images">
                <img src={discoverImg} alt="" />
                <img src={discoverBg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
