import '../../../../styles/style.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Item from '../../../../Components/Item'
import { Product } from '../../../../types/Product'

import cancel from '../../../../assets/images/cancel.svg'
import { AuthContext } from '../../../../Contexts/Auth/AuthContext'

import fetchImages from '../../../../utils/FetchImages'

type SizesModalDTO = {
  itemModal: boolean
  setItemModal: React.Dispatch<React.SetStateAction<boolean>>
  product: Product | undefined
}

export default function Sizes(props: SizesModalDTO) {
  const { itemModal, setItemModal, product } = props

  const auth = useContext(AuthContext)
  const wrapperRef = useRef(null)

  const [products, setProducts] = useState<Product[]>([])
  const [announcementModal, setAnnouncementModal] = useState(false)
  const [selectAnnouncement, setSelectAnnouncement] = useState<Product>()

  const [productImages, setProductImages] = useState<any>({})

  const getProducyBySize = async () => {
    const products = await auth.getProductSizeByDropId({
      size: JSON.parse(product?.sizes || '')[0],
      dropIdNumber: product?.dropIdNumber,
      limit: 30,
      page: 0
    })
    setProducts(products?.data?.results)
  }

  useEffect(() => {
    getProducyBySize()
  }, [])

  useEffect(() => {
    const fetchProductImagesForAll = async () => {
      for await (const product of products) {
        for await (const image of JSON.parse(product.images)) {
          await fetchImages(image, '/product/product-image/get/', setProductImages, auth)
        }
      }
    }

    fetchProductImagesForAll()
  }, [products])

  return (
    <>
      {announcementModal && (
        <Item
          productModal={announcementModal}
          setProductModal={setAnnouncementModal}
          product={selectAnnouncement}
          wrapperRef={wrapperRef}
        />
      )}

      <div
        className="anor_fn_modal report_box"
        style={
          itemModal && !announcementModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                maxHeight: 'calc(100vh - 1px)',
                overflowY: 'auto',
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div
          className="modal_in"
          style={
            itemModal && !announcementModal
              ? {
                  opacity: 1,
                  visibility: 'visible',
                  zIndex: 9999,
                  maxHeight: 'calc(100vh - 1px)',
                  overflowY: 'auto',
                  transform: 'scale(1) translateY(0px)'
                }
              : { transform: 'scale(1) translateY(0px)' }
          }
        >
          <div className="modal_closer" onClick={() => setItemModal(false)}>
            <img src={cancel} alt="" className="fn__svg" />
          </div>
          <div className="anor_fn_product_page">
            <div className="anor_fn_product">
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '25px',
                  marginRight: '20px',
                  marginLeft: '20px',
                  justifyContent: 'center'
                }}
              >
                <div className="container small" style={{ padding: '0% 45%' }}>
                  <h4
                    className="initial_header_sizes_modal"
                    style={{ marginBottom: '20%' }}
                  >
                    {JSON.parse(product?.sizes || '')[0]}
                  </h4>
                </div>
                <h4 style={{ marginBottom: '6%' }}>{product?.name}</h4>

                {products?.map((product, index) => (
                  <div className="sizes_content" key={index}>
                    <div className="size_row">
                      <div className="size_image">
                        <img
                          src={productImages ? productImages[JSON.parse(product?.images)[0].split('.')[0]] : null}
                          alt=""
                        />
                      </div>
                      <div className="size_price">
                        <h3 style={{ marginBottom: '0' }}>
                          R$ {product?.price}
                        </h3>
                      </div>
                      <div className="size_button">
                        <a
                          onClick={() => {
                            setAnnouncementModal(true)
                            setSelectAnnouncement(product)
                          }}
                          style={{ marginBlock: '0' }}
                        >
                          VER
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
