import { ProductImageDatabase } from '../types/Product'

export const b64toBlob = (
  b64Data: string | undefined,
  contentType = '',
  sliceSize = 512
) => {
  const b64DataString = String(b64Data)
  const byteCharacters = atob(b64DataString)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const filterBase64ImageData = (
  imageData: string
): ProductImageDatabase | undefined => {
  try {
    const imageType = imageData.split(';')[0].replace('data:', '')
    const imageMetadata = imageData.split(',')[1]
    const image: ProductImageDatabase = { imageType, imageMetadata }
    return image
  } catch (error) {
    console.error(error)
    return undefined
  }
}
