/* eslint-disable no-unused-vars */
import '../../styles/style.css'
import '../../styles/base.css'
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import InputMask from 'react-input-mask'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { toast } from 'react-toastify'
import { AccountChecker } from '../../types/User'

export default function Recover() {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [cpf, setCpf] = useState('')
  const [cellphone, setCellphone] = useState('')
  const [step, setStep] = useState('data')
  const [account, setAccount] = useState<AccountChecker>()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const validateInputs = () => {
    if (!email || !email.includes('@')) {
      toast.error('Email inserido inválido')
      return false
    }
    if (!cpf) {
      toast.error('CPF inválido')
      return false
    }
    if (!cellphone) {
      toast.error('Celular inválido')
      return false
    }
    return true
  }

  const validatePasswords = () => {
    if (!password) {
      toast.error('Senha inválida')
      return false
    }
    if (password.length < 8) {
      toast.error('A senha deve ter 8 digítos')
      return false
    }
    if (password !== confirmPassword) {
      toast.error('As senhas devem ser iguais')
      return false
    }
    return true
  }

  const handleRecoverUserData = async () => {
    if (validateInputs()) {
      try {
        const response = await auth.recoverUserData({ email, cpf, cellphone })
        if (response?.data?.id) {
          setStep('password')
          setAccount({ id: response?.data?.id, success: response?.data?.success })
        }

        return toast.error(response?.data?.message)
      } catch (error: any) {
        console.error(error)
        return toast.error(
          `Erro ${error?.response?.text} no processo de validação. Tente novamente mais tarde`
        )
      }
    }
  }

  const handleRegisterNewAccount = async () => {
    if (validatePasswords()) {
      try {
        const response = await auth.recreatePassword({ id: account?.id || '', password })
        if (response?.data?.affected > 0) {
          navigate('/signin')
          return toast.success('Senha atualizada com sucesso')
        }

        return toast.error('Erro ao atualizar senha')
      } catch (error: any) {
        console.error(error)
        return toast.error(
          `Erro ${error?.response?.text} no processo de mudança de senha`
        )
      }
    }
  }

  return (
    <>
      <div className="anor_fn_sign_in">
        <div className="container">

          {step === 'data' && (
            <div className="sign_in">
              <div className="title_holder">
                <h3 className="fn_title">Recuperação de Senha</h3>
                <p className="fn_desc">
                  Preencha os campos abaixo para sua segurança
                </p>
              </div>
              <form id="formlogin" method="post">
                <div className="form">
                  <label className="field">Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="seuemail@mail.com"
                    onChange={e => setEmail(e.target.value)}
                  />
                  <label className="field">CPF</label>
                  <InputMask
                    type="text"
                    name="cpf"
                    id="cpf"
                    placeholder=""
                    onChange={e => setCpf(e.target.value)}
                    mask="999.999.999-99"
                  />
                  <label className="field">Celular</label>
                  <InputMask
                    type="text"
                    name="cellphone"
                    id="cellphone"
                    placeholder=""
                    onChange={e => setCellphone(e.target.value)}
                    mask="(99) 99999-9999"
                  />
                  <a
                    className="anor_fn_button sign-in-button"
                    onClick={() => handleRecoverUserData()}
                  >
                    Confirmar
                  </a>
                </div>
              </form>
            </div>
          )}

          {step === 'password' && (
            <div className="sign_in">
              <div className="title_holder">
                <h3 className="fn_title">Registre a nova senha</h3>
                <p className="fn_desc">
                  Preenche a sua nova senha
                </p>
              </div>
              <form id="formlogin" method="post">
                <div className="form">
                  <label className="field">Senha</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                  <label className="field">Confirme a senha</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  <a
                    className="anor_fn_button sign-in-button"
                    onClick={() => handleRegisterNewAccount()}
                  >
                    Salvar
                  </a>
                </div>
              </form>
            </div>
          )}

        </div>
      </div>
    </>
  )
}
