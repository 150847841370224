import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetOneCompanyDTO } from '../../../../types/Company'
import fetchImages from '../../../../utils/FetchImages'
import { AuthContext } from '../../../../Contexts/Auth/AuthContext'

type StoresProps = {
  setEditModal: (value: React.SetStateAction<string>) => void
  editModal: string
  setAction: (value: React.SetStateAction<string | undefined>) => void
  setCompanyCategory: (value: React.SetStateAction<string | undefined>) => void
  getSearchCompanyResults: (keyword: string) => Promise<void>
  companies: GetOneCompanyDTO[]
  companyCategory: string | undefined
  setCompanyModal: (value: React.SetStateAction<string>) => void
  setReportModal: (value: React.SetStateAction<string>) => void
}

export default function Stores(props: StoresProps) {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const [companyImages, setCompanyImages] = useState<any>({})

  const {
    setEditModal,
    editModal,
    setAction,
    setCompanyCategory,
    getSearchCompanyResults,
    companies,
    companyCategory,
    setCompanyModal,
    setReportModal
  } = props

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const company of companies) {
        await fetchImages(company.image as string, '/companies/company-image/get/', setCompanyImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [companies])

  return (
    <div
      className="anor_fn_add_item_page"
      id="storesacoes"
      style={{ marginBottom: '111px' }}
    >
      <div className="container small" style={{ padding: 0 }}>
        <div className="anor_fn_add_item">
          <div className="field_part">
            <div className="fields_content">
              <ul className="fields">
                <div className="anor_fn_ranking_page" style={{ padding: 0 }}>
                  <div className="container small">
                    <div className="ranking_page">
                      <div className="page_title_holder" style={{ padding: 0 }}>
                        <h3 className="fn_title">Lojas</h3>
                        <div className="filters">
                          <div className="dd_filter">
                            <div className="input_wrapper">
                              <select
                                name="gender"
                                className="filter_list"
                                onChange={e =>
                                  setCompanyCategory(e.target.value)
                                }
                              >
                                <option
                                  defaultValue={'active'}
                                  value={'active'}
                                >
                                  Ativas
                                </option>
                                <option value={'blocked'}>Bloquedas</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="fn_search">
                          <input
                            type="text"
                            id="search_data"
                            autoComplete="off"
                            className="form-control input-lg"
                            placeholder="Procurar"
                            onChange={e =>
                              getSearchCompanyResults(e.target.value)
                            }
                            style={{ marginBottom: '20px' }}
                          />
                        </div>

                        <div className="anor_fn_items_list">
                          <ul
                            className="anor_fn_list"
                            data-cols="5"
                            data-gap="20"
                          >
                            {companies.map(
                              (company, index) =>
                                company.state === companyCategory && (
                                  <li key={index}>
                                    <div className="fn__single_item">
                                      <div className="img_holder">
                                        <a
                                          className="full_link"
                                          onClick={() =>
                                            navigate(`/l/${company?.link}`)
                                          }
                                        ></a>
                                        <img
                                          src={companyImages && company?.image
                                            ? companyImages[company?.image?.split('.')[0]]
                                            : null}
                                          style={{
                                            width: '100%',
                                            height: '220px',
                                            objectFit: 'cover'
                                          }}
                                          alt=""
                                        />
                                        <div className="action_box">
                                          <a
                                            className="action_btn"
                                            style={{ bottom: '10px' }}
                                            onClick={() =>
                                              setEditModal(
                                                company.id === editModal
                                                  ? ''
                                                  : company.id
                                              )
                                            }
                                          >
                                            <span
                                              style={
                                                editModal === company.id
                                                  ? {
                                                      transform:
                                                        'rotate(90deg)',
                                                      transition:
                                                        'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                                    }
                                                  : {
                                                      transition:
                                                        'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                                    }
                                              }
                                            ></span>
                                          </a>
                                          <div
                                            className="action_popup"
                                            style={
                                              editModal === company.id
                                                ? {
                                                    opacity: 1,
                                                    visibility: 'visible',
                                                    transform: `translate(${0}px, ${-5}px)`
                                                  }
                                                : {}
                                            }
                                          >
                                            <ul>
                                              <li>
                                                <a
                                                  className="anor_fn_place_item"
                                                  onClick={() => {
                                                    setCompanyModal(company.id)
                                                    setAction('verify')
                                                  }}
                                                >
                                                  Verificar
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="anor_fn_place_item"
                                                  onClick={() => {
                                                    setCompanyModal(company.id)
                                                    setAction('unverify')
                                                  }}
                                                >
                                                  Desverificar
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="anor_fn_share_item"
                                                  onClick={() => {
                                                    setCompanyModal(company.id)
                                                    setAction('blocked')
                                                  }}
                                                >
                                                  Bloquear
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="anor_fn_share_item"
                                                  onClick={() => {
                                                    setReportModal(company.id)
                                                    setAction('report')
                                                  }}
                                                >
                                                  Denuncias
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="title_holder"
                                        style={{ padding: '10px 5px' }}
                                      >
                                        <h3
                                          className="fn_title"
                                          style={{ fontSize: '14px' }}
                                        >
                                          <a>{company?.name}</a>
                                        </h3>
                                      </div>
                                    </div>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
