import '../../styles/style.css'
import '../../styles/base.css'
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { toast } from 'react-toastify'

export default function SignIn() {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const validateInputs = () => {
    if (!email || !email.includes('@')) {
      toast.error('Email inserido inválido')
      return false
    }
    if (!password) {
      toast.error('Senha inserida inválida')
      return false
    }
    return true
  }

  const handleSignIn = async () => {
    if (validateInputs()) {
      try {
        const response = await auth.signIn({ email, password })
        if (response) {
          navigate('/', { replace: true })
          window.location.reload()
        }
        return toast.error('Houve um problema durante o login')
      } catch (error: any) {
        console.error(error)
        return toast.error(
          `Erro ${error?.response?.text} no processo de login. Tente novamente mais tarde`
        )
      }
    }
  }

  return (
    <>
      <div className="anor_fn_sign_in">
        <div className="container">
          <div className="sign_in">
            <div className="title_holder">
              <h3 className="fn_title">Entrar</h3>
              <p className="fn_desc">
                Preencha os campos abaixo para entrar com a sua conta
              </p>
            </div>
            <form id="formlogin" method="post">
              <div className="form">
                <label className="field">Email</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="seuemail@mail.com"
                  onChange={e => setEmail(e.target.value)}
                />
                <label className="field">Senha</label>
                <input
                  name="password"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  style={{ marginBottom: '8px' }}
                />
                <p className="fn_desc" style={{ textAlign: 'right', marginBottom: '24px' }}>
                  <Link to={'/recover'}>Esqueci a senha</Link>
                </p>
                <a
                  className="anor_fn_button sign-in-button"
                  onClick={() => handleSignIn()}
                >
                  Entrar
                </a>
                <p className="fn_desc">
                  Não tem conta?{' '}
                  <Link to={'/signup'}>Crie agora</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
