import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import { GiConfirmed } from 'react-icons/gi'
import { IoMdCloseCircleOutline } from 'react-icons/io'
// @ts-ignore
import MultiImageInput from 'react-multiple-image-input'

import { CreateDropDTO, DropData, UpdateDropData } from '../../../../types/Drop'
import cancel from '../../../../assets/images/cancel.svg'
import { AuthContext } from '../../../../Contexts/Auth/AuthContext'
import formatter from '../../../../utils/PriceFormatter'
import {
  b64toBlob,
  filterBase64ImageData
} from '../../../../providers/base64ToBlob'
import CurrencyInput from 'react-currency-input-field'
import fetchImages from '../../../../utils/FetchImages'

type StoresProps = {
  drops: DropData[]
}

export default function Drops(props: StoresProps) {
  const { drops } = props

  const crop = {
    unit: '%',
    aspect: 1 / 1,
    width: '100',
    height: 100
  }

  const auth = useContext(AuthContext)

  const [editModal, setEditModal] = useState<number | ''>()
  const [dropModal, setDropModal] = useState<number | ''>()
  const [action, setAction] = useState('')

  const [dropName, setDropName] = useState('')
  const [price, setPrice] = useState<string | undefined>('')
  const [birthDate, setBirthDate] = useState<string | undefined>('')
  const [images, setImages] = useState([])

  const [dropImages, setDropImages] = useState<any>({})

  const handleDeleteDrop = async (dropId: number | '' | undefined) => {
    const response = await auth.deleteDrop(dropId)
    window.location.reload()
    if (response?.status === 200)
      return toast.success('Drop removido com sucesso')
    return toast.error('Erro ao remover drop. Tente novamente...')
  }

  const handleUpdateDrop = async (data: UpdateDropData) => {
    const response = await auth.updateDrop(data)
    window.location.reload()
    if (response?.status === 200)
      return toast.success('Drop atualizado com sucesso')
    return toast.error('Erro ao atualizar drop. Tente novamente...')
  }

  const handleCreateDrop = async (data: CreateDropDTO) => {
    const { launch, name, retail } = data

    // const imageResponse = await auth.uploadDropImage(image)

    const newImageUrls: string[] = []

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(images)) {
      const imageData = filterBase64ImageData(value)

      const blob = await b64toBlob(
        imageData?.imageMetadata,
        imageData?.imageType
      )
      const file = await new File([blob], 'product')
      const uploadResponse = await auth.uploadDropImage(file)
      const path = uploadResponse?.data?.imagePath || ''
      newImageUrls.push(path)
    }

    const response = await auth.createDrop({
      image: newImageUrls[0],
      launch,
      name,
      retail
    })

    if (response?.status === 201) {
      toast.success('Drop criado com sucesso')
      window.location.reload()
    }

    if (response?.response?.status === 403) {
      return toast.error(response?.response?.data?.message)
    }

    return toast.error('Erro ao criar drop. Tente novamente...')
  }

  const loadDropData = (drop: DropData) => {
    setDropName(drop?.name)
    setPrice((drop?.retail || '').replace('.00', '').replace(',', ''))
    setBirthDate(drop?.launch)
  }

  const validateInputData = () => {
    if (!dropName) {
      toast.error('Digite um nome válido')
      return false
    }
    if (!price) {
      toast.error('Digite um preço válido')
      return false
    }
    if (!birthDate) {
      toast.error('Digite uma data de lançamento válida')
      return false
    }
    return true
  }

  async function handleSubmit(e: { preventDefault: () => void }) {
    e.preventDefault()
    if (!validateInputData()) return

    action === 'edit'
      ? handleUpdateDrop({
        id: editModal,
        name: dropName,
        retail: formatter.format(parseInt(price || '')).replace('R$', ''),
        launch: birthDate
      })
      : handleCreateDrop({
        name: dropName,
        launch: birthDate,
        retail: formatter.format(parseInt(price || '')).replace('R$', ''),
        image: '',
        min: ''
      })
  }

  useEffect(() => {
    const fetchDropImagesForAll = async () => {
      for await (const drop of drops) {
        await fetchImages(drop.image, '/drop/drop-image/get/', setDropImages, auth)
      }
    }

    fetchDropImagesForAll()
  }, [drops])

  return (
    <>
      <div
        className="anor_fn_modal share_box"
        style={
          dropModal
            ? {
                opacity: 1,
                visibility: 'visible',
                zIndex: 9999,
                transform: 'scale(1) translateY(0px)'
              }
            : { transform: 'scale(1) translateY(0px)' }
        }
      >
        <div className="modal_in">
          <div className="modal_closer">
            <img
              src={cancel}
              alt=""
              className="fn__svg"
              onClick={() => setDropModal('')}
            />
          </div>
          <div className="modal_content">
            <div className="share_title">
              {action === 'delete' ? 'Remover Drop?' : 'Editar'}
            </div>
            <div className="share_list">
              {action === 'delete' && (
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        handleDeleteDrop(editModal)
                        setDropModal('')
                        setAction('')
                      }}
                      target="_blank"
                    >
                      <GiConfirmed />
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setDropModal('')
                        setAction('')
                      }}
                      target="_blank"
                    >
                      <IoMdCloseCircleOutline />
                    </a>
                  </li>
                </ul>
              )}

              {(action === 'edit' || action === 'create') && (
                <div className="anor_fn_add_item_page">
                  <div className="container small">
                    <div className="anor_fn_add_item" style={{ padding: 0 }}>
                      <form id="addproduto" encType="multipart/form-data">
                        <div className="field_part">
                          <div className="fields_content">
                            <ul className="fields">
                              <div
                                className="field_item"
                                style={{ marginBottom: '3%' }}
                              >
                                <label className="label">Nome</label>
                                <input
                                  type="text"
                                  id="search_data"
                                  placeholder="Air Jordan 1..."
                                  autoComplete="off"
                                  name="dropName"
                                  value={dropName}
                                  className="form-control input-lg"
                                  onChange={e => {
                                    setDropName(e.target.value)
                                  }}
                                />
                              </div>

                              <div
                                className="field_item"
                                style={{ marginBottom: '3%' }}
                              >
                                <label className="label">Preço</label>
                                <CurrencyInput
                                  id="preco"
                                  name="preco"
                                  placeholder="R$"
                                  decimalsLimit={2}
                                  onValueChange={value => setPrice(value)}
                                  value={price}
                                />
                              </div>

                              <div
                                className="field_item"
                                style={{ marginBottom: '3%' }}
                              >
                                <label className="label">Lançamento</label>
                                <InputMask
                                  id="data"
                                  type="text"
                                  name="birthDate"
                                  onChange={e => setBirthDate(e.target.value)}
                                  value={birthDate}
                                  mask="99/99/9999"
                                />
                              </div>

                              <div
                                className="field_item"
                                style={{ marginBottom: '2%' }}
                              >
                                <label className="label">Foto do Drop</label>
                                <MultiImageInput
                                  max={1}
                                  width={450}
                                  height={450}
                                  theme="light"
                                  images={images}
                                  setImages={setImages}
                                  cropConfig={{
                                    crop,
                                    ruleOfThirds: true,
                                    minWidth: 500
                                  }}
                                />
                              </div>

                              <div
                                className="field_item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <div className="links">
                                  <button
                                    className="additional_modal_button small add"
                                    onClick={handleSubmit}
                                  >
                                    Salvar
                                  </button>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="anor_fn_add_item_page"
        id="storesacoes"
        style={{ marginBottom: '111px' }}
      >
        <div className="container small" style={{ padding: 0 }}>
          <div className="anor_fn_add_item">
            <div className="field_part">
              <div className="fields_content">
                <ul className="fields">
                  <div className="anor_fn_ranking_page" style={{ padding: 0 }}>
                    <div className="container small">
                      <div className="ranking_page">
                        <div
                          className="page_title_holder"
                          style={{ padding: 0 }}
                        >
                          <h3 className="fn_title">Drops</h3>
                          {/* <div className="filters">
                            <div className="dd_filter">
                              <div className="input_wrapper">
                                <select
                                  name="gender"
                                  className="filter_list"
                                  onChange={e =>
                                    setCompanyCategory(e.target.value)
                                  }
                                >
                                  <option
                                    defaultValue={'active'}
                                    value={'active'}
                                  >
                                    Ativas
                                  </option>
                                  <option value={'blocked'}>
                                    Bloquedas
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div> */}

                          <div className="filters">
                            <div className="dd_filter">
                              <div className="input_wrapper">
                                <div className="links">
                                  <button
                                    className="additional_modal_button small add"
                                    onClick={() => {
                                      setDropModal(-1)
                                      setAction('create')
                                      setDropName('')
                                      setPrice('')
                                      setBirthDate('')
                                      setImages([])
                                    }}
                                    style={{
                                      marginRight: '0',
                                      backgroundColor: 'transparent'
                                    }}
                                  >
                                    Novo Drop
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="fn_search">
                            <input
                              type="text"
                              id="search_data"
                              autoComplete="off"
                              className="form-control input-lg"
                              placeholder="Procurar"
                              onChange={e =>
                                getSearchDropResults(e.target.value)
                              }
                              style={{ marginBottom: '20px' }}
                            />
                          </div> */}

                          <div className="anor_fn_items_list">
                            <ul
                              className="anor_fn_list"
                              data-cols="5"
                              data-gap="20"
                            >
                              {drops.map((drop, index) => (
                                <li key={index}>
                                  <div className="fn__single_item">
                                    <div className="img_holder">
                                      <a className="full_link"></a>
                                      <img
                                        src={dropImages
                                          ? dropImages[drop?.image?.split('.')[0]]
                                          : null}
                                        style={{
                                          width: '100%',
                                          height: '220px',
                                          objectFit: 'cover'
                                        }}
                                        alt=""
                                      />
                                      <div className="action_box">
                                        <a
                                          className="action_btn"
                                          style={{ bottom: '10px' }}
                                          onClick={() => {
                                            setEditModal(
                                              drop?.id === editModal
                                                ? ''
                                                : drop?.id
                                            )
                                          }}
                                        >
                                          <span
                                            style={
                                              editModal === drop?.id
                                                ? {
                                                    transform: 'rotate(90deg)',
                                                    transition:
                                                      'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                                  }
                                                : {
                                                    transition:
                                                      'all .3s cubic-bezier(0.42, 0, 0.58, 1)'
                                                  }
                                            }
                                          ></span>
                                        </a>
                                        <div
                                          className="action_popup"
                                          style={
                                            editModal === drop.id
                                              ? {
                                                  opacity: 1,
                                                  visibility: 'visible',
                                                  transform: `translate(${0}px, ${-5}px)`
                                                }
                                              : {}
                                          }
                                        >
                                          <ul>
                                            <li>
                                              <a
                                                className="anor_fn_place_item"
                                                onClick={() => {
                                                  setDropModal(drop.id)
                                                  setAction('edit')
                                                  loadDropData(drop)
                                                }}
                                              >
                                                Editar
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="anor_fn_share_item"
                                                onClick={() => {
                                                  setDropModal(drop.id)
                                                  setAction('delete')
                                                }}
                                              >
                                                Remover
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="title_holder"
                                      style={{ padding: '10px 5px' }}
                                    >
                                      <h3
                                        className="fn_title"
                                        style={{ fontSize: '14px' }}
                                      >
                                        <a>{drop?.name}</a>
                                      </h3>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
